<template>
  <div class="multipleChoice">
    <div class="label">{{ fieldName }}</div>
    <div class="options">
      <div :class="['option', 'flexVert', selectedOption === option?.value && 'selected']" v-for="option of options" :key="option" @click="handleChange(option)">
        <div class="toggle">
          <div class="center"></div>
        </div>
        <div class="text">
          <h3>{{ option?.display?.main }}</h3>
          <p>{{ option?.display?.secondary }}</p>
        </div>
      </div>
    </div>

    <div class="error" v-show="error.message" :style="`background-Color: ${error.type === 'warning' ? '#efa25f' : '#ef5f5f'}`">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill: none; stroke: #fff; stroke-miterlimit: 10; stroke-width: 32px" />
        <polyline points="220 220 252 220 252 336" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px" />
        <line x1="208" y1="340" x2="296" y2="340" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 32px" />
        <path style="fill: #fff" d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z" />
      </svg>
      <p>{{ error.message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: "",
    };
  },
  props: {
    fieldName: String,
    options: Array,
    error: Object,
    default: String,
  },
  methods: {
    handleInputError() {
      console.warn(this.error.message);
    },
    handleChange(option) {
      if (!option.value) return;
      this.selectedOption = option.value;
      this.$emit("userInput", option.value);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.default) {
        this.selectedOption = this.default;
      }
    }, 200);
  },
  watch: {
    error: {
      deep: true,
      handler() {
        this.handleInputError();
      },
    },
  },
};
</script>

<style scoped>
.error {
  margin-top: 10px;

  display: grid;
  justify-content: left;
  align-items: center;
  grid-template-columns: 18px auto;
  gap: 8px;

  padding: 4px 8px;
  transition: 0.2s;
  background-color: #ef5f5f;
  border-radius: 2px;
  user-select: none;
  pointer-events: none;
}
.error svg {
  width: 18px;
  height: 18px;
}
.error p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.error p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.label {
  font-size: 16px;
  font-weight: 700;
  color: #3e3e3e;
  margin-bottom: 10px;
}
.options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  user-select: none;
  -webkit-user-select: none;
}
.option {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  cursor: pointer;

  gap: 10px;
  padding: 10px 20px 10px 10px;
}
.option h3 {
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
}
.option p {
  font-size: 14px;
  font-weight: 300;
  color: #3e3e3e;
}
.toggle {
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 1px solid #b9b9b9;
  padding: 4px;
}
.center {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.selected {
  border: 1px solid #4a4a4a;
}
.selected .toggle {
  border: 1px solid #4a4a4a;
}
.selected .center {
  background-color: #4a4a4a;
}
.option:hover .center {
  background-color: #b9b9b9;
}
.selected:hover .center {
  background-color: #4a4a4a;
}

@media only screen and (max-width: 400px) {
  .error {
    grid-template-columns: 16px auto;
    gap: 8px;

    padding: 4px 8px;
  }
  .error svg {
    width: 1rem;
    height: 1rem;
  }
  .error p {
    font-size: 12px;
  }

  .option {
    gap: 8px;
    padding: 8px 16px 8px 8px;
    width: fit-content;
  }
  .option h3 {
    font-size: 14px;
  }
  .option p {
    font-size: 11px;
  }
  .toggle {
    width: 16px;
    height: 16px;
    padding: 3px;
  }
}
</style>
