<template>
  <div class="box">
    <div class="container" v-if="bundle == undefined">
      <div class="meta">
        <p>Wystąpił błąd</p>
        <h1>Zniżka nie istnieje</h1>
      </div>
    </div>
    <div class="container" v-if="bundle != undefined">
      <div class="meta">
        <p>{{ bundle.text }}</p>
        <h1>{{ bundle.name }}</h1>
      </div>

      <div class="grid">
        <div class="required">
          <div class="label">
            <h2>Wymagane produkty</h2>
          </div>

          <div class="products" v-for="(subset, i) of requiredProducts" :key="subset">
            <div class="info">
              <h3>
                <span>{{ createProductString(bundle.requiredProducts[i]) }}</span> <span>{{ subset.quantityAdded }} z {{ bundle.requiredProducts[i].quantity }}</span>
              </h3>
              <div class="toggle" @click="toggleRequired[i] = !toggleRequired[i]">
                <img :style="toggleRequired[i] && 'rotate: 180deg'" src="data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='black' d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>" />
              </div>
            </div>
            <div :class="['accordion-content', toggleRequired[i] || 'hidden']">
              <div class="accordion">
                <div class="list">
                  <div class="item" v-for="product in subset.products" :key="product.id">
                    <div class="type product" v-if="!product.product._type">
                      <ProductComponent style="flex: 4" :data="product" />
                      <button @click="requiredClicked(i, true, product.product._id)"><img src="/assets/svg/koszyk.svg" alt="Cart" /></button>
                    </div>
                    <div class="type set" v-if="product.product._type">
                      <ProductComponent style="pointer-events: none" :data="product" />
                      <button @click="requiredClicked(i, false, `/set-creator?name=${product.product.name}`)"><img src="/assets/svg/koszyk.svg" alt="Add" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bundled">
          <div class="label">
            <h2>Produkty gratis</h2>
          </div>

          <div class="products" v-for="(subset, i) of bundledProducts" :key="subset">
            <div class="info">
              <h3>
                <span>{{ createProductString(bundle.bundledProducts[i]) }}</span> <span>{{ subset.quantityAdded }} z {{ bundle.bundledProducts[i].quantity }}</span>
              </h3>
              <div class="toggle" @click="toggleBundled[i] = !toggleBundled[i]">
                <img :style="toggleBundled[i] && 'rotate: 180deg'" src="data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='black' d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>" />
              </div>
            </div>
            <div :class="['accordion-content', toggleBundled[i] || 'hidden']">
              <div class="accordion">
                <div class="list">
                  <div class="item" v-for="product in subset.products" :key="product.id">
                    <div class="type product" v-if="!product.product._type">
                      <ProductComponent style="flex: 4" :data="product" />
                      <button @click="bundledClicked(i, true, product.product._id)"><img src="/assets/svg/koszyk.svg" alt="Cart" /></button>
                    </div>
                    <div class="type set" v-if="product.product._type">
                      <ProductComponent style="pointer-events: none" :data="product" />
                      <button @click="bundledClicked(i, false, `/set-creator?name=${product.product.name}`)"><img src="/assets/svg/koszyk.svg" alt="Add" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="explanation">Aby skorzystać z oferty wystarczy dodać powyżej wymienione produkty do koszyka. Zniżka zostanie naliczona automatycznie.</p>
    </div>
  </div>
</template>

<script setup>
import ProductComponent from "@/components/ProductComponent.vue";
import appStore from "@/stores/appStore.js";
import { ref, watch, defineEmits } from "vue";
import { useRoute } from "vue-router";
const emit = defineEmits("addToCart");
const route = useRoute();
const bundle = ref(appStore.bundles.find((bundle) => bundle.name === route.params.name));
watch(appStore, () => {
  bundle.value = appStore.bundles.find((bundle) => bundle.name === route.params.name);
});
watch(
  () => route.params.name,
  () => {
    bundle.value = appStore.bundles.find((bundle) => bundle.name === route.params.name);
    sortProductsAndSets();
  }
);

const requiredClicked = (index, isProduct, item) => {
  if (isProduct) {
    emit("addToCart", item, 1);
  } else {
    window.open(item, "_blank");
  }
  requiredProducts.value[index].quantityAdded++;
};

const bundledClicked = (index, isProduct, item) => {
  if (isProduct) {
    emit("addToCart", item, 1);
  } else {
    window.open(item, "_blank");
  }
  bundledProducts.value[index].quantityAdded++;
};

const toggleRequired = ref(new Array(10).fill(true));
const toggleBundled = ref(new Array(10).fill(true));

const createProductString = (product) => {
  if (product?.type?.startsWith("Zestaw")) {
    return `${product.quantity}x ${product.type}`;
  }
  // const isPlural = product.quantity > 1 ? 0 : 1;
  if (product.type && product.element) {
    return `${product.quantity}x ${productText[product.type].text[1]} ${product.element}`;
  }
  if (product.type) {
    return `${product.quantity}x ${productText[product.type].text[1]}`;
  }
  if (product.element) {
    return `${product.quantity}x Produkt ${productText[product.element].text[1]}`;
  }
  return "";
};

const productText = {
  mgl: {
    text: ["Mgiełki", "Mgiełka"],
  },
  mgs: {
    text: ["Mgiełki Mini", "Mgiełka Mini"],
  },
  swn: {
    text: ["Świece", "Świeca"],
  },
  fire: {
    text: ["Fire", "Fire"],
  },
  air: {
    text: ["Air", "Air"],
  },
  earth: {
    text: ["Earth", "Earth"],
  },
  water: {
    text: ["Water", "Water"],
  },
};

let products = [];
const requiredProducts = ref([]);
const bundledProducts = ref([]);

const sortProductsAndSets = () => {
  if (bundle.value == undefined) return;

  bundle.value.requiredProducts.forEach((requiredProduct) => {
    const list = [];
    products.forEach((product) => {
      let valid = false;
      if (product._type === "SET") {
        if (product.name === requiredProduct.type) {
          valid = true;
        }
      } else if (requiredProduct.type && requiredProduct.element) {
        if (product.productLine === requiredProduct.type && product.element === requiredProduct.element) {
          valid = true;
        }
      } else if (requiredProduct.type) {
        if (product.productLine === requiredProduct.type) {
          valid = true;
        }
      } else if (requiredProduct.element) {
        if (product.element === requiredProduct.element) {
          valid = true;
        }
      }
      if (valid) {
        list.push({
          product,
          quantity: 0,
        });
      }
    });
    requiredProducts.value.push({
      quantityAdded: 0,
      products: list,
    });
  });

  bundle.value.bundledProducts.forEach((bundledProduct) => {
    const list = [];
    products.map((product) => {
      let valid = false;
      if (bundledProduct.type && bundledProduct.element) {
        if (product.productLine === bundledProduct.type && product.element === bundledProduct.element) {
          valid = true;
        }
      } else if (bundledProduct.type) {
        if (product.productLine === bundledProduct.type) {
          valid = true;
        }
      } else if (bundledProduct.element) {
        if (product.element === bundledProduct.element) {
          valid = true;
        }
      }
      if (valid) {
        list.push({
          product,
          quantity: 0,
        });
      }
    });
    bundledProducts.value.push({
      quantityAdded: 0,
      products: list,
    });
  });
};
const getProductsAndSets = async () => {
  const req = await fetch("/api/products", {
    method: "GET",
  });
  const res = await req.json();
  products = res.data || [];
  if (appStore.loadedData.sets) {
    appStore.sets.forEach((set) => {
      products.push({
        _type: "SET",
        _id: set.name,
        ...set,
      });
    });
  } else {
    await appStore.getSets();
    appStore.sets.forEach((set) => {
      products.push({
        _type: "SET",
        _id: set.name,
        ...set,
      });
    });
  }
  sortProductsAndSets();
};
getProductsAndSets();
</script>

<style scoped>
.box {
  max-width: 1600px;
  padding: 40px 20px 20px 20px;
  margin: 0 auto;
  min-height: 100vh;
}
.box .meta p {
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3a;
}
.box .meta h1 {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}

.box .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.box .label {
  position: relative;
  margin: 40px 0 20px 0;
  max-width: 800px;
}
.box .label::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #818181;
}
.box .label h2 {
  display: inline;
  padding-right: 10px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  color: #3a3a3a;
}

.box .products {
  max-width: 800px;
  margin: 10px 0;
  background-color: #fff;
}

.box .products .info {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box .products .info h3 {
  font-size: 16px;
  font-weight: 500;
  color: #3a3a3a;
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}
.box .products .info .toggle {
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 100px;
  background-color: #f4f4f4;
  cursor: pointer;
  user-select: none;
}
.box .products .info .toggle:hover {
  background-color: #e4e4e4;
}
.box .products .info img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.box .products .type {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
}
.box .products .type button {
  width: 100%;
  padding: 8px 8px 4px 8px;

  background-color: var(--main-color-1);
  box-shadow: 0 0 0 var(--main-color-1-light);
  border: none;
  border-radius: 6px;

  cursor: pointer;
  transition: 0.4s;
}
.box .products .type button:hover {
  background-color: var(--main-color-1-dimm);
  box-shadow: 0 0 20px var(--main-color-1-light);
}
.box .products .type button img {
  height: 32px;
  aspect-ratio: 1/1;
}

.edit {
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  padding: 0 0 0 20px;

  border-left: 1px dotted #bfbfbf;
}
.edit .options {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 4px;
}
.edit .options div {
  width: 100%;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-align: center;
}
.edit .options div:hover {
  background-color: #e8e8e8;
}
.edit .options h4 {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
}
.edit .options h3 {
  background-color: #ededed;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
}

.box .explanation {
  text-align: center;
  margin: 40px 0;
  font-size: 14px;
  font-weight: 400;
  color: #3a3a3a;
}

.accordion-content {
  display: grid;
  grid-template-rows: 1fr;
  opacity: 1;
  transition: 200ms;
}
.accordion-content.hidden {
  grid-template-rows: 0fr !important;
  opacity: 0;
}
.accordion-content .accordion {
  overflow: hidden;
}
.accordion-content .list {
  border-top: 1px solid #f4f4f4;
  padding: 20px 20px 10px 20px;
}
</style>
