<template>
  <div class="browse">
    <h1 class="header">{{ this.string }}</h1>
    <div class="results" v-if="this.results.length">
      <div class="result" @click="!loading && this.$router.push(`/products/${result._id}`)" v-for="result of results" :key="result">
        <!-- <div class="click">
          <svg :class="loading && 'loadingSvg'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.071 16.071L20.314 20.314M7.18804 2.23901L7.96504 5.13601M5.13604 7.96501L2.23804 7.18801M13.95 4.05001L11.828 6.17201M6.17104 11.828L4.05104 13.95M9.00004 9.00001L14 21L15.774 15.774L21 14L9.00004 9.00001Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p :class="loading && 'loadingElement'">Dowiedz się więcej</p>
        </div> -->
        <div :class="loading && 'loadingElement'" class="imgSkeleton">
          <img v-show="!loading" :src="result.images[0]" :alt="result.name" />
        </div>

        <div v-if="!Array.isArray(result.name)" :class="['classicTitle', 'titleDiv', loading && 'loadingContainer']">
          <h1>
            {{ result.name }}
          </h1>
        </div>
        <div v-if="Array.isArray(result.name)" :class="['customTitle', 'titleDiv', loading && 'loadingContainer']">
          <h1>
            {{ result.name[0] }}<span><br />{{ result.name[1] }}</span>
          </h1>
        </div>

        <div class="details">
          <div :class="loading && 'loadingDiv'">
            <h6>Dostępność:</h6>
            <h5>{{ result.avalibility || "..." }}</h5>
          </div>
          <div :class="loading && 'loadingDiv'">
            <h6>Pojemność:</h6>
            <h5>{{ result?.volume || "..." }}</h5>
          </div>
        </div>
        <div class="shop" :class="loading && 'loadingContainer'">
          <div class="priceFlex">
            <h2>{{ `${result.price}`.slice(0, -2) + "," + `${result.price}`.slice(-2) }}zł</h2>
            <h3 v-if="result.discountedFrom">{{ `${result.discountedFrom}`.slice(0, -2) + "," + `${result.discountedFrom}`.slice(-2) }}zł</h3>
          </div>
          <div class="actions">
            <AddToCartButton @clicked="!loading && this.$emit('addToCart', result._id, 1)" />
            <ShowMoreButton @click="!loading && this.$router.push(`/products/${result._id}`)" />
          </div>
        </div>
        <div class="delivery">
          <svg :class="loading && 'loadingSvg'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21L4 16.5V7.5L12 3L20 7.5V12M12 12L20 7.5M12 12V21M12 12L4 7.5M15 18H22M19 15L22 18L19 21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p :class="loading && 'loadingElement'">Wysyłka w 1-3 dni roboczych</p>
        </div>
      </div>
    </div>
    <div class="noResults" v-if="!results.length">
      <h1>Nie znaleźliśmy pasujących produktów</h1>
    </div>
  </div>
</template>

<script>
import AddToCartButton from "@/components/AddToCartButton.vue";
import ShowMoreButton from "@/components/ShowMoreButton.vue";
import appStore from "@/stores/appStore.js";

export default {
  data() {
    return {
      string: "",
      results: [],
      texts: {
        mgiełka: "Mgiełki nawilżające i deodoryzujące",
        mgielka: "Mgiełki nawilżające i deodoryzujące",

        świeca: "Świece sojowe sensualny płomień",
        swieca: "Świece sojowe sensualny płomień",
      },

      loading: true,
    };
  },
  methods: {
    async getResults() {
      let url = "/api/products";
      if (this.$route.query.type === "sale") {
        url += "/onSale";
      } else if (this.$route.query.element && this.$route.query.type) {
        url += `?element=${this.$route.query.element}&type=${this.$route.query.type}`;
      } else if (this.$route.query.type) {
        url += `?type=${this.$route.query.type}`;
      } else if (this.$route.query.element) {
        url += `?element=${this.$route.query.element}`;
      }

      const req = await fetch(url, {
        method: "GET",
      });
      const res = await req.json();
      this.results = res.data || [];
      this.results = this.results.map((r) => {
        r.name = appStore.analyzeUpdateName(r.name);
        return r;
      });
      this.loading = false;
    },
    rearrangeResults(results) {
      const sortedElements = {};
      results.forEach((res) => {
        if (!sortedElements[res.element]) sortedElements[res.element] = [];
        sortedElements[res.element].push(res);
      });
      return Object.values(sortedElements).flat();
    },
    writeString() {
      if (this.$route.query.element && this.$route.query.type) {
        this.string = `Produkty ${this.$route.query.element} typu ${this.$route.query.type}`;
      } else if (this.$route.query.type) {
        this.string = this.texts[this.$route.query.type];
      } else if (this.$route.query.element) {
        this.string = `Produkty ${this.$route.query.element}`;
      } else {
        this.string = "Produkty";
      }
      document.title = `MyAURA - ${this.string}`;
    },
  },
  created() {
    for (let i = 0; i < 4; i++) this.results.push({ _id: undefined, name: "Świetnie pachnący produkt MyAURA", price: 9999, avalibility: "\xa0", images: [undefined] });
    this.getResults();
    this.writeString();
  },
  watch: {
    $route() {
      this.loading = true;
      this.results = [];
      for (let i = 0; i < 4; i++) this.results.push({ _id: undefined, name: "Świetnie pachnący produkt MyAURA", price: 9999, avalibility: "\xa0", images: [undefined] });

      this.getResults();
      this.writeString();
    },
  },
  components: {
    AddToCartButton,
    ShowMoreButton,
  },
};
</script>

<style scoped>
.loadingElement {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingContainer {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingContainer * {
  opacity: 0;
}
.loadingSvg {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingSvg path {
  stroke: transparent !important;
}
.loadingDiv {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingDiv h5 {
  color: transparent !important;
}
.loadingDiv h6 {
  color: transparent !important;
}

.browse {
  max-width: 1640px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 120px;
  min-height: calc(100vh - var(--nav-height));
}
.header {
  font-size: 55px;
  font-weight: 700;

  margin: 40px 0 20px 0;
}
.results {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, 370px);
  gap: 40px;
}
.result {
  padding: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  box-shadow: 0 0 30px #0000000d;
  transition: 0.3s ease;
}
.result:hover {
  transform: perspective(800px) rotateX(1deg) rotateY(-1deg) scale(1.02);
  box-shadow: 15px 15px 30px #00000014;
}
.result:hover img {
  transform: perspective(800px) rotateX(-2deg) rotateY(2deg) scale(1.03);
  -webkit-filter: drop-shadow(-15px -15px 20px #00000012);
  filter: drop-shadow(-15px -15px 20px #00000012);
}
/* .result .hoverableUnderline {
  text-decoration: #ffffff00 underline;
}
.result:hover .hoverableUnderline {
  text-decoration: #9f9f9f underline;
} */
.click {
  text-align: left;
  cursor: pointer;

  margin: -5px -5px 0 -5px;
  padding: 5px;
  display: flex;
  align-items: center;

  background-color: #f5f5f5;
  border-radius: 6px;

  transition: 0.2s;
}
.result:hover .click {
  background-color: #e0e0e0;
  margin: -20px -20px -15px -20px;
  padding: 20px;
  border-radius: 0;
}
.click svg {
  height: 20px;
  aspect-ratio: 1/1;
}
.click path {
  stroke: rgb(149, 149, 149);
}
.click p {
  margin-left: 10px;
  font-size: 15px;
  color: rgb(149, 149, 149);
  display: inline;
}
.result .imgSkeleton {
  height: 300px;
  width: 100%;
  margin: 4px 0 10px 0;
}
.result img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: 0.25s ease;
  color: transparent;
}
.titleDiv {
  margin: auto 0 10px 0;
}
.result h1 {
  text-align: left;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  color: #1b1b1b;
}
.result h1 span {
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  color: #1b1b1b;
}
.details {
  text-align: left;
  /* border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7; */

  /* padding: 0.5rem 0; */
  margin-bottom: 0.5rem;

  display: flex;
  gap: 10px;
  overflow-x: auto;
}
.details div {
  background-color: #f5f5f5;
  padding: 10px 30px 10px 15px;
  border-radius: 6px;
  width: 100%;
}
.details h6 {
  font-size: 13px;
  line-height: 13px;
  color: #505050;
  font-weight: 400;
  margin: 2px 0 5px 0;
}
.details h5 {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
}
.shop {
  background-color: #fff6e1;
  margin-bottom: 1rem;
  border-radius: 6px;
}
.shop .priceFlex {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 5px 5px 5px 15px;
}
.shop .actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0 15px 10px 15px;
}
.shop .actions button {
  width: 100%;
}
.result h2 {
  font-size: 21px;
  font-weight: 600;
  color: #1b1b1b;
}
.result h3 {
  margin-bottom: auto;
  font-size: 15px;
  font-weight: 500;
  color: #ec6363;
  text-decoration: line-through;
}
.result button {
  height: 40px;
}
.delivery {
  font-size: 15px;
  color: gray;

  display: flex;
  align-items: center;
  justify-content: center;
}
.delivery svg {
  width: 15px;
  aspect-ratio: 1/1;
  stroke: #4ecb71;
  margin-right: 6px;
}

.noResults h1 {
  font-size: 26px;
  font-weight: 400;
  color: rgb(117, 117, 117);
}

@media only screen and (max-width: 1000px) {
  .header {
    font-size: 40px;
    line-height: 60px;
  }
  .noResults h1 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 500px) {
  .result:hover {
    transform: perspective(0) rotateX(0) rotateY(0) scale(1);
    box-shadow: 0 0 30px #0000000d;
  }
  .result:hover img {
    transform: perspective(0) rotateX(0) rotateY(0) scale(1);
    -webkit-filter: drop-shadow(-15px -15px 20px #00000012);
    filter: drop-shadow(-15px -15px 20px #00000012);
  }
  .browse {
    padding: 0 15px;
  }
  .header {
    font-size: 35px;
    line-height: 55px;
  }
  .noResults h1 {
    font-size: 20px;
  }

  .results {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .result {
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow: hidden;
  }
  .result .imgSkeleton {
    height: 180px;
    margin: 5px 0;
  }
  .result .titleDiv {
    margin: auto 0 5px 0;
  }
  .result h1 {
    font-size: 12px;
    line-height: 15px;
  }
  .result h1 span {
    font-size: 10px;
    line-height: 13px;
  }
  .result:hover .click {
    margin: -10px -10px -5px -10px;
    padding: 10px;
  }
  .click svg {
    height: 14px;
  }
  .click p {
    margin-left: 6px;
    font-size: 8px;
  }
  .result button {
    height: 30px;
  }
  .details {
    margin-bottom: 0.5rem;
    gap: 5px;
  }
  .details div {
    padding: 4px 8px;
  }
  .details h6 {
    font-size: 8px;
  }
  .details h5 {
    font-size: 10px;
  }
  .result h2 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 8px;
  }
  .result h3 {
    font-size: 10px;
    font-weight: 300;
  }
  .result button {
    width: 32px;
    border-radius: 0.5rem;
  }
  .result button img {
    height: 20px;
  }
  .shop {
    padding: 8px;
    margin-bottom: 0.5rem;
  }
  .shop .priceFlex {
    padding: 0;
  }
  .shop .actions {
    padding: 0;
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .shop .actions .buyNow {
    display: none;
  }
  .delivery {
    font-size: 8px;
  }
  .delivery svg {
    width: 11px;
  }
}
</style>
