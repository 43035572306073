<template>
  <nav>
    <div class="flex-container">
      <div class="nav-left">
        <router-link to="/" class="minContent"><img id="logo" src="/assets/svg/logo.svg" alt="My Aura" /></router-link>
        <ul class="nav-links">
          <li>
            <router-link to="/products?type=mgiełka">Mgiełki</router-link>
          </li>
          <li><router-link to="/products?type=świeca">Świece</router-link></li>
          <li class="navIcon">
            <router-link to="/set-creator">Zestawy</router-link>
            <img src="/assets/svg/gift.svg" alt="Gift Icon" />
          </li>
          <li>
            <router-link to="/products?type=sale">Wyprzedaż <b>%</b></router-link>
          </li>
          <li>
            <router-link to="/about">O nas</router-link>
          </li>
          <li v-if="this.token">
            <router-link to="/panel">Panel</router-link>
          </li>
        </ul>
      </div>
      <div class="nav-right">
        <h6 v-if="!this.token" class="profile noMobile" @click="this.$emit('openAccountPrompt')">Moja MyAura</h6>
        <div v-if="this.token" :class="[`profileDiv`, `noMobile`, !this.user.name && `userLoading`]" @click="this.$emit('openAccountPrompt')">
          <img src="/assets/img/user.png" alt="Użytkownik" />
          <h6>{{ this.user.name || "U-NAME" }}</h6>
        </div>
        <div class="koszyk-container" :number="cartNumber" @click="this.$emit('openCartPrompt')">
          <img class="koszyk-icon" src="/assets/svg/koszyk.svg" alt="Koszyk" />
        </div>
        <img id="menu" :src="this.open ? '/assets/img/close.png' : '/assets/img/menu.png'" alt="Menu" @click="this.open = !this.open" />
      </div>
    </div>
    <div :class="['mobileNav', this.open && 'visible']">
      <div class="content">
        <h3>Produkty</h3>
        <ul>
          <li>
            <router-link to="/products?type=mgiełka">Mgiełki</router-link>
          </li>
          <li><router-link to="/products?type=świeca">Świece</router-link></li>
          <li class="navIcon">
            <router-link to="/set-creator">Zestawy</router-link>
            <img src="/assets/svg/gift.svg" alt="Gift Icon" />
          </li>
          <li>
            <router-link to="/products?type=sale">Wyprzedaż <b>%</b></router-link>
          </li>
          <li>
            <router-link to="/about">O nas</router-link>
          </li>
        </ul>
        <h3 v-if="this.token">Konto</h3>
        <ul v-if="this.token">
          <li>
            <router-link to="/panel">Panel</router-link>
          </li>
        </ul>
      </div>
      <div class="account">
        <h6 v-if="!this.token" class="profile" @click="this.$emit('openAccountPrompt')">Moja MyAura</h6>
        <div v-if="this.token" class="profileDiv" @click="this.$emit('openAccountPrompt')">
          <img src="/assets/img/user.png" alt="Użytkownik" />
          <h6>{{ this.user.name }}</h6>
        </div>
        <!-- <div class="koszyk-container" :number="cartNumber" @click="this.openCartPrompt">
          <img class="koszyk-icon" src="/assets/svg/koszyk.svg" alt="Koszyk" />
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      open: false,

      cartNotification: "transparent",
      cartNumber: 0,
    };
  },
  props: {
    token: String,
    user: Object,
    offlineCart: Array,
  },
  methods: {
    setCartNotification() {
      if (this.token ? this.user.cart.length > 0 : this.offlineCart.length > 0) {
        this.cartNotification = "#ff1f1f";
      } else {
        this.cartNotification = "transparent";
      }
    },
    calculateAmountCart() {
      if (this.token) {
        this.cartNumber = this.user.cart.reduce((acc, cur) => acc + cur.quantity, 0);
      } else {
        this.cartNumber = this.offlineCart.reduce((acc, cur) => acc + cur.quantity, 0);
      }
    },
    openAccountPrompt() {
      this.$emit("openAccountPrompt");
      this.open = false;
    },
    openCartPrompt() {
      this.$emit("openCartPrompt");
      this.open = false;
    },
  },
  watch: {
    $route() {
      this.open = false;
    },
    user: {
      handler() {
        this.setCartNotification();
        this.calculateAmountCart();
      },
      deep: true,
    },
    offlineCart() {
      this.setCartNotification();
      this.calculateAmountCart();
    },
  },
  created() {
    this.setCartNotification();
    this.calculateAmountCart();
  },
};
</script>

<style scoped>
nav {
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgb(236, 236, 236);
  backdrop-filter: blur(10px);
  width: 100vw;
  user-select: none;
  -webkit-user-select: none;
}
.flex-container {
  display: flex;
  justify-content: space-between;

  max-width: 1600px;
  margin: 0 auto;
  padding: 10px;

  overflow: hidden;
}
.nav-left,
.nav-right,
.minContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-left {
  gap: 10px;
}

#logo {
  margin: 0 10px;
  height: 50px;
}
#menu {
  display: none;
  margin: auto 10px;
  height: 30px;
}
.nav-links {
  display: flex;
  margin: auto 8px;
  color: rgb(0, 0, 0);
}
.nav-links li {
  margin: auto 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  padding: 5px;
  list-style: none;
  cursor: pointer;
  position: relative;
}
.nav-links li a {
  color: black;
  text-decoration: none;
  height: min-content;
}
.nav-links li::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);

  width: calc(100% + 30px);
  /* DOESNT MATTER */
  height: 100px;
  background-color: #00000010;
  opacity: 0;
  z-index: -1;
  transition: 0.2s;
}
.nav-links li:hover::after {
  opacity: 1;
}
.nav-right {
  margin: 0 10px;
}
.profile {
  height: 40px;
  line-height: 40px;
  background-color: var(--action-color);
  color: white;

  margin: auto 15px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 2px;
  cursor: pointer;
}
.profile:hover {
  background-color: var(--action-color-hover);
}
.profileDiv {
  border: 1px dashed var(--action-color);

  margin: auto 15px;
  padding: 0 10px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.profileDiv:hover {
  background-color: rgba(166, 166, 255, 0.25);
}
.profileDiv h6 {
  display: inline;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 5px;
}
.profileDiv img {
  display: inline;
  height: 30px;
  aspect-ratio: 1/1;
}
.koszyk-container {
  display: flex;
  width: 42px;
  height: 42px;
  padding: 6px;
  border-radius: 0;
  overflow: hidden;
  cursor: pointer;

  position: relative;
}
.koszyk-container::before {
  content: attr(number);
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  font-size: 8px;
  line-height: 13px;
  font-weight: 400;
  color: white;
  text-align: center;

  overflow: hidden;
  background-color: v-bind("cartNotification");
  transition: 0.4s;
}

.koszyk-container img {
  height: 100%;
  width: 100%;
}

.mobileNav {
  display: none;

  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: var(--nav-height);
  left: 100%;

  width: 100%;
  height: calc(100vh - var(--nav-height));
  height: calc(100dvh - var(--nav-height));

  background-color: rgb(255, 255, 255);
  transition: 0.5s cubic-bezier(0.15, 1.01, 0.41, 0.96);
}
.account {
  padding: 20px 15px 40px 15px;
  display: flex;
}
.content {
  padding: 20px 30px;
  width: fit-content;

  overflow-y: auto;
  overflow-x: hidden;
}
.content h3 {
  font-size: 36px;
  font-weight: 500;
}
.content ul {
  list-style: none;
  border-left: 1px solid rgb(222, 222, 222);
  margin-bottom: 20px;
}
.content li {
  margin: 5px 10px;
  font-size: 24px;
  font-weight: 400;
}
.content li a {
  color: black;
  text-decoration: none;
}
.navIcon {
  display: flex;
  align-items: center;
  gap: 8px;
}
.navIcon img {
  height: 18px;
}

.visible {
  transform: translateX(-100%);
}

.userLoading {
  animation: 1.5s loading linear infinite;
  border: none;
}
.userLoading * {
  opacity: 0;
}

@media only screen and (max-width: 1000px) {
  #menu {
    display: block;
  }
  .nav-links {
    display: none;
  }
  /* .nav-right {
    display: none;
  } */
  .flex-container {
    padding: 7px;
  }
  .nav-right {
    margin: 0;
    gap: 10px;
  }
  .noMobile {
    display: none;
  }
  #logo {
    margin-left: 3px;
    height: 36px;
  }
  .koszyk-container {
    width: 36px;
    height: 36px;
    padding: 6px;
  }
  .koszyk-container::before {
    top: 3px;

    width: 10px;
    height: 10px;

    font-size: 6px;
    line-height: 11px;
  }
  #menu {
    margin: auto 10px;
    height: 26px;
  }
  .mobileNav {
    display: flex;
  }
}
</style>
