<template>
  <div class="overlay">
    <div class="prompt">
      <img class="close" src="/assets/img/close.png" alt="Close" @click="this.$emit('closePrompt')" />
      <div class="content">
        <div class="scroll">
          <div class="top">
            <!-- <img src="/assets/svg/aura-cart.svg" alt="Koszyk" /> -->
            <div class="text">
              <h1>Twój koszyk</h1>
              <h2>{{ (this.list.length === 0 && "0 produktów") || (this.list.length === 1 && "1 produkt") || (this.list.length > 1 && 5 > this.list.length && `${this.list.length} produkty`) || `${this.list.length} produktów` }}<span class="span-dot"></span><span class="hover-span hover-rabat">Kody Rabatowe</span></h2>
            </div>
          </div>
          <div class="bundles">
            <div class="bundle" v-for="bundle of this.bundles" :key="bundle.name">
              <div class="info">
                <h3>Oferta "{{ bundle.name }}"</h3>
                <p>{{ bundle.text }}</p>
              </div>
              <div class="action">
                <router-link :to="`/bundles/${bundle.name}`">Skorzystaj z oferty &rarr;</router-link>
              </div>
            </div>
          </div>
          <div class="products" v-if="this.cart.length">
            <div class="desc">
              <p class="p">PRODUKT</p>
              <p class="q">ILOŚĆ</p>
            </div>
            <div class="product" v-for="(item, i) of this.list" :key="i">
              <ProductComponent :data="item" :showQuantity="false" :showLoading="false" />
              <div class="edit flexVert">
                <div class="options">
                  <div @click="this.editCart(item.product._id, 1)">
                    <h4>&#43;</h4>
                  </div>
                  <h3>{{ item.quantity }}x</h3>
                  <div @click="this.editCart(item.product._id, -1)">
                    <h4>&#45;</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="sum">
              <h1>Suma: {{ priceString(sum - discountOnCart) }}</h1>
              <div class="toCheckout flexVert">
                <button @click="checkout">Do kasy <img src="/assets/svg/checkout-cart.svg" alt="Checkout" /></button>
                <p>Płatności obsługiwane przez: &nbsp;<img src="/assets/img/Przelewy24_logo.png" alt="Przelewy24" />&nbsp;&nbsp;<img src="/assets/img/Paypo_logo_black.png" alt="PayPo" /></p>
              </div>
            </div>
          </div>
          <div class="noProducts" v-if="!this.cart.length"><h1>Twój koszyk jest pusty</h1></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductComponent from "@/components/ProductComponent.vue";
import appStore from "@/stores/appStore.js";

export default {
  data() {
    return {
      sum: 0,
      list: [],
      discountOnCart: 0,
    };
  },
  props: {
    token: String,
    cart: Array,
  },
  components: {
    ProductComponent,
  },
  methods: {
    priceString(price) {
      return appStore.priceString(price);
    },
    async prepareList() {
      const checkoutData = {
        cart: this.cart,
        discount: null,
        delivery: null,
      };
      appStore
        .genereteCartCheckoutData(checkoutData)
        .then((data) => {
          this.sum = data.sum;
          this.list = data.items;
          this.discountOnCart = data.discount.cart;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editCart(product, quantity) {
      this.$emit("editCart", product, quantity);
    },
    checkout() {
      this.$router.push("/checkout");
      this.$emit("closePrompt");
    },
  },
  watch: {
    cart: {
      immediate: true,
      deep: true,
      handler() {
        if (this.cart.length) {
          this.prepareList();
          this.bundles = appStore.bundles;
        }
      },
    },
    appStore: {
      immediate: true,
      deep: true,
      handler() {
        if (this.cart.length) {
          this.prepareList();
          this.bundles = appStore.bundles;
        }
      },
    },
  },
  mounted() {
    this.bundles = appStore.bundles;
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("closePrompt");
      }
    });
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.25rem;
}

.hover-rabat::after {
  content: "Prosimy o wpisanie kodu rabatowego do odpowiedniego pola po przejściu do kasy." !important;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.prompt {
  background-color: #f5f5f5;
  aspect-ratio: 10/6;
  max-height: 85vh;
  width: 1000px;

  position: relative;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;

  aspect-ratio: 1/1;
  width: 40px;
  cursor: pointer;
}

.content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll {
  min-height: 100%;
  height: min-content;
  width: 100%;
}

.top {
  margin: 0 20px 0 20px;
  padding: 20px 0 20px 0;

  display: flex;
  align-items: center;
  gap: 20px;

  border-bottom: 1px dotted rgb(191, 191, 191);
}
.top img {
  width: 40px;
  aspect-ratio: 1/1;
}
.top h1 {
  font-size: 30px;
  font-weight: 700;
  color: #161616;
}
.top h2 {
  font-size: 15px;
  font-weight: 500;
  color: #373737;
}

button img {
  display: inline;
  width: 22px;
  aspect-ratio: 1/1;
  margin-left: 0.8rem;
}
button {
  margin: 10px 0 0 0;
  padding: 0.6rem 1.2rem 0.6rem 1.4rem;
  border-radius: 2px;
  font-size: 20px;

  font-weight: 700;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: rgb(70, 67, 255);
  color: white;
}
button:hover {
  background-color: rgb(55, 52, 247);
}

.bundles {
  margin: 10px 20px;
}
.bundle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #c6f7c6;
  border: 1px solid #85e185;

  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.bundle h3 {
  font-size: 15px;
  font-weight: 600;
  color: #3e3e3e;
  line-height: 1;
  margin-bottom: 3px;
}
.bundle p,
.bundle a {
  font-size: 12px;
  font-weight: 400;
  color: #3e3e3e;
  line-height: 1.25;
  text-decoration: none;
}
.bundle a {
  font-weight: 500;
  background-color: #0001;
  padding: 8px 12px;
  border-radius: 4px;
}
.bundle a:hover {
  background-color: #0002;
}

.product,
.desc {
  display: grid;
  grid-template-columns: auto 150px;
  gap: 20px;
  align-items: center;
  margin: 0 20px;
}
.desc {
  text-align: left;
  color: #484848;
  font-weight: 400;
  font-size: 13px;
}
.desc .p {
  padding-left: 80px;
}
.desc .q {
  padding-left: 20px;
  border-left: 1px dotted #bfbfbf;
}
.products {
  margin-top: 20px;
  padding-bottom: 160px;
}
.product {
  margin-top: 10px;
  border-radius: 4px;
}
.product img {
  max-height: 60px;
  max-width: 60px;
  justify-self: center;
}
.product h1 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;

  display: block;
  line-height: 20px;
}
.product a {
  text-decoration: none;
  color: #333333;
}
.product a:hover {
  text-decoration: underline;
}

.product h2 {
  font-size: 15px;
  font-weight: 500;
  color: #373737;
}
.product h3 {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  color: #4b4b4b;
}

.edit {
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  padding: 0 0 0 20px;

  border-left: 1px dotted #bfbfbf;
}
.options {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 4px;
}
.options div {
  width: 100%;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-align: center;
}
.options div:hover {
  background-color: #e8e8e8;
}
.options h4 {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
}
.options h3 {
  background-color: #ededed;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
}

.sum {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: white;
  padding: 10px 20px 20px 20px;
}
.sum:after {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: linear-gradient(#fff0, #ffff);
}
.sum h1 {
  color: #161616;
  font-weight: 700;
  font-size: 30px;
}
.sum .toCheckout {
  gap: 16px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}
.sum .toCheckout button {
  margin: 0;
}
.sum .toCheckout p {
  font-size: 12px;
  color: #6e6e6e;
  display: flex;
  gap: 8px;
  align-items: center;
}
.sum .toCheckout img {
  height: 30px;
}

.noProducts h1 {
  margin: 40px 20px;
  text-align: center;
  color: #6e6e6e;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
  .prompt {
    width: 900px;
  }
}

@media only screen and (max-width: 1000px) {
  .prompt {
    aspect-ratio: 5/4;
    width: 800px;
  }
  .desc .p {
    padding-left: 70px;
  }
}
@media only screen and (max-width: 850px) {
  .prompt {
    aspect-ratio: unset;
    width: calc(100% - 60px);
    height: calc(100vh - 200px);
  }
  .scroll {
    max-height: 70vh;
  }

  .product,
  .desc {
    grid-template-columns: 1fr 120px;
  }
}

@media only screen and (max-width: 600px) {
  .prompt {
    width: calc(100% - 40px);
  }
  .close {
    width: 30px;
  }

  .products {
    margin-top: 20px;
    padding-bottom: 180px;
  }

  .sum .toCheckout {
    flex-direction: column;
  }
  .sum .toCheckout button {
    width: 100%;
  }
  .sum .toCheckout p {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 520px) {
  .product,
  .desc {
    margin: 5px 10px;
  }
  .desc .p {
    padding-left: 60px;
  }
  .desc .q {
    padding-left: 10px;
  }
  .edit {
    padding: 0 0 0 10px;
  }
}
@media only screen and (max-width: 480px) {
  .prompt {
    width: calc(100% - 30px);
  }

  .top {
    margin: 0 10px 0 10px;
    padding: 10px 0 10px 0;
  }

  .bundles {
    margin: 10px 10px;
  }

  .product,
  .desc {
    grid-template-columns: 1fr 100px;
    gap: 10px;
  }

  .options h3 {
    font-size: 15px;
  }
  .options h4 {
    font-size: 15px;
  }

  .product h1 {
    font-size: 15px;
  }
  .product h2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 450px) {
  .prompt {
    aspect-ratio: unset;
    width: calc(100% - 20px);
    height: calc(100vh - 100px);
  }
  .element h1 {
    font-size: 18px;
  }

  .sum h1 {
    font-size: 24px;
  }
  .sum .toCheckout button {
    font-size: 16px;
  }

  .sum .toCheckout p {
    font-size: 10px;
  }
  .sum .toCheckout img {
    height: 16px;
  }
}
</style>
