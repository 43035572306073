<template>
  <div class="home">
    <section class="headFull" @mousedown="handleDragStart" @mousemove="handleDrag" @mouseup="handleDragEnd" @touchstart="handleDragStart" @touchmove="handleDrag" @touchend="handleDragEnd" @touchcancel="handleDragEnd">
      <div class="slide" :style="`opacity: ${this.currentSlide == i ? '1' : '0'}; backdrop-filter: blur(${this.currentSlide == i ? '10px' : '0'}); z-index: ${this.currentSlide == i ? '0' : '-1'}`" v-for="(slide, i) of slideshow.slides" :key="slide._id" :id="slide._id">
        <div class="background" :style="`background: ${slide.backgroundColor}`">
          <img v-if="slide.backgroundType === 'img'" :src="this.isMobile() ? slide.backgroundSrcMobile : slide.backgroundSrc" :alt="slide.title" :id="`${slide._id}_background`" />
          <video :ref="`vid_${i}`" muted playsinline autoplay style="pointer-events: none" v-if="slide.backgroundType === 'vid'" :alt="slide.title" :id="`${slide._id}_background`">
            <source v-for="source of this.isMobile() ? slide.backgroundSrcMobile : slide.backgroundSrc" :key="source" :src="source" type="video/mp4" />
          </video>
          <div v-if="slide.backgroundType === 'gradient'" :style="`background-image: ${slide.backgroundGradient}`"></div>
        </div>

        <div v-if="slide.backgroundType === 'vid'" class="toggleMute" @click="toggleMute()">{{ this.muted === true ? "Dźwięk" : "Wycisz" }}</div>

        <div v-if="slide.type === 'infoSlide'" class="headFullContent" :style="`background-image: linear-gradient(${this.isMobile() ? '0' : '90'}deg, ${slide.backgroundColor}80, ${slide.backgroundColor}50, transparent)`">
          <div class="bottom">
            <h1 :id="`${slide._id}_title`" :style="`color: ${slide.textColor || 'white'}`">
              {{ slide.title }}
            </h1>
            <p class="max2LinesText" :id="`${slide._id}_description`" :style="`color: ${slide.textColor || 'white'}`">
              {{ slide.description }}
            </p>
            <div class="actionArea">
              <router-link class="primaryBtn" :style="`color: ${slide.textColor}`" :to="slide.primaryButtonLink || '/'" v-if="slide.primaryButton">{{ slide.primaryButton }}</router-link>
              <router-link class="secondaryBtn" :style="`color: ${slide.textColor}`" :to="slide.secondaryButtonLink || '/'" v-if="slide.secondaryButton">{{ slide.secondaryButton }}<img src="/assets/svg/arrow-right.svg" alt="Arrow" /></router-link>
            </div>
          </div>
        </div>

        <div v-if="slide.type === 'productDisplay'" class="headFullContent productDisplay" :style="slide.backgroundType === 'gradient' ? '' : `background-image: linear-gradient(${this.isMobile() ? '0' : '90'}deg, ${slide.backgroundColor}80, ${slide.backgroundColor}50, transparent)`">
          <div class="bottom">
            <h1 :id="`${slide._id}_title`" :style="`color: ${slide.textColor || 'white'}`">{{ slide.title }}</h1>
            <div class="list">
              <div :class="['product', bestSellers.loading && 'loadingContainer']" v-for="product of slide.products.slice(0, isMobile() ? 2 : 4)" :key="product._id" @click="!bestSellers.loading && takeTo(`/products/${product._id}`)">
                <img :src="product.images?.at(0)" @load="bestSellerImageLoaded" :alt="product.name" />
                <div class="info">
                  <div v-if="!Array.isArray(product.name)" class="classicTitle titleDiv">
                    <h2>
                      {{ product.name }}
                    </h2>
                  </div>
                  <div v-if="Array.isArray(product.name)" class="customTitle titleDiv">
                    <h2>
                      {{ product.name[0] }}<span><br />{{ product.name[1] }}</span>
                    </h2>
                  </div>
                  <p v-show="!this.isMobile()" :style="`background-color: ${getElementColor(product.element)}`">{{ product.element }}</p>
                  <h3>
                    {{ product.price ? `${product.price}`.slice(0, -2) + "," + `${product.price}`.slice(-2) + "zł" : "0zł" }}<span v-if="product.discountedFrom">{{ product.discountedFrom ? `${product.discountedFrom}`.slice(0, -2) + "," + `${product.discountedFrom}`.slice(-2) + "zł" : "0zł" }}</span>
                  </h3>
                  <AddToCartButton :simple="!this.isMobile() && this.isNarrowerThan(1100)" @click.stop="!bestSellers.loading && this.$emit('addToCart', product._id, 1)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="slideshow">
        <div class="timer" v-for="(slide, i) of slideshow.slides" :key="slide.name" @click="this.userChangeSlide(i)" @mouseenter="this.stopSpin(i)" @mouseleave="this.startSpin(i)">
          <div class="progress" :style="`width: ${(slide.currentTime / slide.duration) * 100}%`"></div>
        </div>
      </div>
    </section>

    <div class="types">
      <div class="type" @click="takeTo('/products?type=mgiełka')">
        <img class="grayscale" src="/assets/img/fire-both.webp" alt="Mgiełka" />
        <div>
          <h2>Mgiełki</h2>
          <p>Dają odświeżenie, nawilżenie, unikalny aromat i poczucie luksusu.</p>
        </div>
      </div>
      <div class="type" @click="takeTo('/products?type=świeca')">
        <img class="grayscale" src="/assets/img/fire-candle.webp" alt="Świeca" />
        <div>
          <h2>Świece</h2>
          <p>Tworzą unikalny, aromatyczny klimat i pozytywnie wpływają na nastrój.</p>
        </div>
      </div>
      <div class="type" @click="takeTo('/set-creator')">
        <img class="grayscale" src="/assets/img/christmas-box.webp" alt="Zestaw" />
        <div>
          <h2>Zestawy</h2>
          <p>Pozwalają skomponować unikalny, pachnący i elegancki prezent.</p>
        </div>
      </div>
    </div>

    <section class="products">
      <div class="productsCont">
        <div class="text">
          <h1>Odkrywaj zapachy MyAURA</h1>
          <p>Misją Aura Concept jest wnoszenie harmonii i piękna do codziennego życia każdego człowieka. Produkty MyAURA mają za zadanie poruszyć zmysły zapachem, eleganckim opakowaniem i wyjątkowymi właściwościami nawilżającymi.</p>
          <router-link to="/products">Zobacz wszystkie produkty<img src="/assets/svg/arrow-right.svg" alt="Arrow" /></router-link>
        </div>
        <div class="productSlider">
          <div class="productGrid">
            <!-- <router-link to="/products?type=mgiełka" class="type wide mgi">
              <img class="grayscale" src="/assets/img/fire-both.webp" alt="Mgiełka" />
              <p>Mgiełki</p>
            </router-link>
            <router-link to="/products?type=świeca" class="type wide swi">
              <img class="grayscale" src="/assets/img/fire-candle.webp" alt="Świeca" />
              <p>Świece</p>
            </router-link> -->
            <router-link to="/products?element=fire" class="type fire">
              <img id="fire" src="/assets/svg/fire-icon.svg" alt="Fire" />
              <p>Fire</p>
            </router-link>
            <router-link to="/products?element=wind" class="type wind">
              <img id="wind" src="/assets/svg/wind-icon.svg" alt="Wind" />
              <p>Wind</p>
            </router-link>
            <router-link to="/products?element=water" class="type water">
              <img id="water" src="/assets/svg/water-icon.svg" alt="Water" />
              <p>Water</p>
            </router-link>
            <router-link to="/products?element=earth" class="type earth">
              <img id="earth" src="/assets/svg/earth-icon.svg" alt="Earth" />
              <p>Earth</p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AddToCartButton from "@/components/AddToCartButton.vue";
import appStore from "@/stores/appStore.js";

export default {
  name: "HomeView",
  methods: {
    handleDragStart() {
      this.isDraggingSlide = true;
    },
    handleDrag(e) {
      if (this.isDraggingSlide === false) return;
      this.slideDragDistance += e.movementX;
      if (this.slideDragDistance > 100) {
        this.slideDragDistance = 0;
        this.isDraggingSlide = false;
        this.prevSlide();
      } else if (this.slideDragDistance < -100) {
        this.slideDragDistance = 0;
        this.isDraggingSlide = false;
        this.nextSlide();
      }
    },
    handleDragEnd() {
      this.isDraggingSlide = false;
      this.slideDragDistance = 0;
    },
    handleArrowPress(e) {
      if (e.key === "ArrowRight") this.nextSlide();
      if (e.key === "ArrowLeft") this.prevSlide();
    },
    changeSlide(i) {
      try {
        const oldSlide = this.slideshow.slides[this.currentSlide];
        if (oldSlide.backgroundType === "vid") {
          const videoElement = document.getElementById(`${oldSlide._id}_background`);
          videoElement.pause();
        }

        this.currentSlide = i;
        const slide = this.slideshow.slides[this.currentSlide];
        slide.currentTime = 0;

        if (slide.backgroundType === "vid") {
          const videoElement = document.getElementById(`${slide._id}_background`);
          videoElement.currentTime = 0;
          videoElement.muted = this.muted;
          videoElement.play();
        }
      } catch (err) {
        console.log(err);
      }
    },

    toggleMute() {
      this.muted = !this.muted;
      const videoElement = document.getElementById(`${this.slideshow.slides[this.currentSlide]._id}_background`);
      videoElement.muted = this.muted;
    },

    userChangeSlide(i) {
      this.changeSlide(i);
      this.slideshow.slides.forEach((slide) => (slide.currentTime = 0));
    },

    startSpin() {
      this.autoSpin = true;

      this.lastTime = Date.now();
      this.slideInterval = setInterval(() => {
        if (!this.autoSpin) this.stopSpin();

        const slide = this.slideshow.slides[this.currentSlide];
        const now = Date.now();
        slide.currentTime += now - this.lastTime;
        this.lastTime = now;

        if (slide.currentTime >= slide.duration) this.nextSlide();
      }, 1000 / 60);
    },
    stopSpin() {
      this.autoSpin = false;
    },

    nextSlide() {
      if (this.currentSlide >= this.slideshow.slides.length - 1) {
        this.slideshow.slides.forEach((slide) => (slide.currentTime = 0));
        this.changeSlide(0);
      } else this.changeSlide(this.currentSlide + 1);
    },
    prevSlide() {
      if (this.currentSlide === 0) {
        this.changeSlide(this.slideshow.slides.length - 1);
      } else this.changeSlide(this.currentSlide - 1);
    },

    isMobile() {
      if (this.windowWidth >= 700) return false;
      return true;
    },
    isNarrowerThan(width) {
      if (this.windowWidth >= width) return false;
      return true;
    },

    titleCarousel() {
      const title = document.querySelector("title");

      const string = "MyAURA - Sklep Internetowy - Mgiełki, Świece Sojowe i Prezenty \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0MyAURA - Sklep Internetowy - Mgiełki, Świece Sojowe i Prezenty";

      let index = 0;
      this.titleInterval = setInterval(() => {
        if (index > 0) {
          title.innerHTML = `&#6158;${string.slice(index)}`;
        } else {
          title.innerHTML = string;
        }

        index++;
        if (index === 84) index = -40;
      }, 250);

      this.$router.beforeEach(() => {
        clearInterval(this.titleInterval);
      });
    },

    async getBestsellers() {
      const res = await fetch(`/api/products/bestsellers?limit=${this.bestSellers.limit}`);
      if (res.status !== 200) return [];
      const data = await res.json();
      return data.data
        .map((b) => {
          b.name = appStore.analyzeUpdateName(b.name);
          return b;
        })
        .slice(0, this.bestSellers.limit);
    },

    bestSellerImageLoaded() {
      this.bestSellers.loaded++;
      if (this.bestSellers.loaded === this.isMobile() ? 2 : this.bestSellers.limit) {
        this.bestSellers.loading = false;
      }
    },

    getElementColor(element) {
      switch (element) {
        case "fire":
          return "#c50000";
        case "wind":
          return "#ffd006";
        case "water":
          return "#66abf0";
        case "earth":
          return "#4b7c0f";
        default:
          return "#000000";
      }
    },

    takeTo(path) {
      this.$router.push(path);
    },
  },
  data() {
    return {
      windowWidth: innerWidth,
      titleInterval: undefined,

      isDraggingSlide: false,
      slideDragDistance: 0,

      muted: true,
      currentSlide: 0,
      slideInterval: undefined,
      lastTime: 0,
      autoSpin: true,

      bestSellers: {
        limit: 4,
        loaded: 0,
        loading: true,
      },

      slideshow: {
        slides: [
          {
            _id: "489u42",
            type: "infoSlide",
            title: "Oferta",
            description: "Kup 4 świece, a otrzymasz mgiełkę zapachową 100ml GRATIS!",
            textColor: "#ffffff",

            primaryButton: "Skorzystaj z oferty",
            primaryButtonLink: "/bundles/Mgiełka 100 ml gratis przy zakupie 4 świec",

            backgroundColor: "#000000",
            backgroundType: "img",
            backgroundSrc: "/assets/img/desktop-baner-3sm.webp",
            backgroundSrcMobile: "/assets/img/mobile-baner-3sm.webp",

            duration: 15 * 1000,
            currentTime: 0,
          },
          {
            _id: "1",
            type: "infoSlide",
            title: "Poczuj się wyjątkowo",
            description: "Produkty MyAURA mają za zadanie poruszyć zmysły zapachem, eleganckim opakowaniem i wyjątkowymi właściwościami nawilżającymi.",
            textColor: "#ffffff",

            // primaryButton: "Kup teraz",
            // primaryButtonLink: "/products",
            // secondaryButton: "Stwórz zestaw",
            // secondaryButtonLink: "/set-creator",

            backgroundColor: "#000000",
            backgroundType: "img",
            backgroundSrc: "/assets/img/mainSlideCrop.webp",
            backgroundSrcMobile: "/assets/img/mainSlideMobile.webp",

            duration: 10 * 1000,
            currentTime: 0,
          },
          {
            _id: "bs",
            type: "productDisplay",
            title: "Wyprzedaż",

            // textColor: "#000",
            // backgroundColor: "#fff",
            // backgroundType: "gradient",
            // backgroundGradient: "background-color: #c3cbdc;background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%)",

            textColor: "#fff",
            backgroundColor: "#000000",
            backgroundType: "img",
            backgroundSrc: "/assets/img/mainSlideCrop.webp",
            backgroundSrcMobile: "/assets/img/mainSlideMobile.webp",

            productsLoadFunction: () => this.getBestsellers(),
            products: Array(4).fill({}),

            duration: 30 * 1000,
            currentTime: 0,
          },
          {
            _id: "0",
            type: "infoSlide",

            // title: "Zapachy są dla Ciebie ważne?",
            // description: "Wierzysz, że otaczanie się ulubionymi zapachami bezpośrednio wpływa na Twój nastrój, energetykę i aurę? Jesteś we właściwym miejscu!",
            // textColor: "#ffffff",

            // primaryButton: "Kup teraz",
            // primaryButtonLink: "/products",
            // secondaryButton: "Dowiedz się więcej",
            // secondaryButtonLink: "/about",

            backgroundColor: "#000000",
            backgroundType: "vid",
            // backgroundSrc: "/assets/vid/MyAuraVideo.mp4",
            backgroundSrc: ["/assets/vid/MyAuraVideo.mp4", "/assets/vid/AURAh264.mp4"],
            // backgroundSrcMobile: "/assets/vid/MyAuraVideo.mp4",
            backgroundSrcMobile: ["/assets/vid/MyAuraVideo.mp4", "/assets/vid/AURAh264.mp4"],

            duration: 60 * 1000,
            currentTime: 0,
          },
          {
            _id: "2",
            type: "infoSlide",
            title: "Art of Packaging",
            description: 'Z przyjemnością informujemy, że nasze opakowania zdobyły najwyższe uznanie jury konkursu "Art of Packaging" w kategorii Opakowanie Cyfrowe.',
            textColor: "#000000",

            backgroundColor: "#ffffff",
            backgroundType: "img",
            backgroundSrc: "/assets/img/artofpackagingdesktop.png",
            backgroundSrcMobile: "/assets/img/artofpackagingmobile.png",

            duration: 10 * 1000,
            currentTime: 0,
          },
        ],
      },
    };
  },

  created() {
    document.title = `MyAURA - Sklep Internetowy - Mgiełki, Świece Sojowe i Prezenty`;
    this.startSpin();

    if (this.$route.query.clearcart == "true") {
      this.$emit("clearCart");
    }

    // setTimeout(() => {
    //   if (innerWidth > 600) this.titleCarousel();
    // }, 1 * 1000);

    this.slideshow.slides.forEach(async (s) => {
      if (s.productsLoadFunction) {
        s.products = await s.productsLoadFunction();
      }
    });

    document.addEventListener("keydown", this.handleArrowPress);
  },

  beforeUnmount() {
    this.stopSpin();
    clearInterval(this.slideInterval);
    document.removeEventListener("keydown", this.handleArrowPress);
  },

  components: {
    AddToCartButton,
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.4);
}
::-webkit-scrollbar-track:hover {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}

.products {
  margin: 80px 0 40px 0;
  padding: 20px 0 20px 0;
  background: #e9e9e9;
  background-image: linear-gradient(22.5deg, #ffffff00 0%, #b0fcaf40 100%);
}
.productsCont {
  max-width: 1600px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 540px auto;
  gap: 60px;
}
.products .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}
.products .text h1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.products .text p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
.products .text a {
  width: fit-content;
  padding: 0.7rem 1.5rem 0.7rem 1.7rem;
  border-radius: 2px;
  font-size: 1rem;

  font-weight: 600;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 1rem;

  border: none;
  background-color: var(--action-color);
  color: white;
}
.products .text a img {
  width: 20px;
  height: 20px;
}
.products .text a:hover {
  background-color: var(--action-color-hover);
}

.productSlider {
  width: 100%;
}
.productGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "fire fire wind wind" "water water earth earth";
  gap: 40px;

  padding: 20px 0;
}
.productGrid .type {
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  background-color: white;
  box-shadow: 0 0 40px #0000000d;
  /* border: 1px solid #dcdcdc; */
  cursor: pointer;
  transition: 0.4s ease;
}

.productGrid .type:hover {
  transform: perspective(800px) rotateX(1deg) rotateY(-1deg) scale(1.02);
  box-shadow: 15px 15px 30px #00000014;
}
.productGrid .wide {
  aspect-ratio: unset;
}
.productGrid .type img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.productGrid .type {
  text-decoration: none;
  color: #1e1e1e;
  font-size: 1rem;
  font-weight: 500;
}

.productGrid .wsz {
  grid-area: wsz;
}
.productGrid .mgi {
  grid-area: mgi;
}
.productGrid .swi {
  grid-area: swi;
}
.productGrid .fire {
  grid-area: fire;
}
.productGrid .wind {
  grid-area: wind;
}
.productGrid .water {
  grid-area: water;
}
.productGrid .earth {
  grid-area: earth;
}

/* ================================================================================== */
/* NEW STYLES ======================================================================= */
/* ================================================================================== */

.slideshow {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);

  margin: 1rem 0;
  height: 5px;
  width: calc(100% - 4rem);

  display: flex;
  justify-content: center;
  gap: 1rem;
}
.slideshow .timer {
  max-width: 100px;
  width: 100%;
  min-width: 40px;
  background: #ffffff61;
  box-shadow: 0 0 10px #0005;
  backdrop-filter: blur(20px);
  border-radius: 2.5px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
}

.slideshow .progress {
  background-color: #ffffffd5;
  box-shadow: 0 0 5px #0005;
  border-radius: 2.5px;
  height: 100%;
}

.slide {
  position: absolute;

  width: 100%;
  height: 100%;
  overflow: hidden;

  transition: 0.6s cubic-bezier(0.76, 0, 0.24, 1);
}

.animate {
  animation: slideIn 0.5s cubic-bezier(0.76, 0, 0.24, 1) 0 forwards;
}

.headFull {
  position: relative;

  margin: 0 auto;

  max-width: 1600px;
  aspect-ratio: 21/9;

  overflow: hidden;

  cursor: col-resize;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.headFullContent {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  padding: 4rem;
  max-width: 50%;
}
.headFullContent h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 115%;
  margin-bottom: 0.6rem;
}
.headFullContent a {
  text-decoration: none;
  cursor: pointer;
}
.headFullContent p {
  font-size: 1.2rem;
  line-height: 125%;
  font-weight: 400;
  margin-bottom: 1.7rem;
}
.headFullContent .actionArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.headFullContent .actionArea a {
  border-radius: 2px;
  font-size: 1rem;

  font-weight: 500;
  transition: 0.2s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.headFullContent .actionArea .primaryBtn {
  padding: 0.7rem 1.3rem;
  background-color: var(--action-color);
  border: 1px solid var(--action-color);
}
.headFullContent .actionArea .primaryBtn:hover {
  background-color: var(--action-color-hover);
  border: 1px solid var(--action-color-hover);
}
.headFullContent .actionArea .secondaryBtn {
  padding: 0.7rem 1.3rem 0.7rem 1.5rem;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
}
.headFullContent .actionArea .secondaryBtn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.headFullContent .actionArea .secondaryBtn img {
  width: 22px;
}

.headFullContent.productDisplay {
  max-width: 100%;
}
.headFullContent.productDisplay .bottom {
  width: 100%;
}
.headFullContent.productDisplay .list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}
.headFullContent.productDisplay .list .product {
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  border-radius: 4px;
  background-color: #fff;
  backdrop-filter: blur(5px);
  cursor: pointer;
  transition: 0.2s;
}
.headFullContent.productDisplay .list .product:hover {
  transform: perspective(800px) rotateX(1deg) rotateY(-1deg) scale(1.02);
  box-shadow: 15px 15px 30px #00000014;
}
.headFullContent.productDisplay .list .product img {
  width: 100%;
  aspect-ratio: 1/2;
  object-fit: contain;
  transition: 0.2s;
}
.headFullContent.productDisplay .list .product:hover img {
  transform: perspective(800px) rotateX(-2deg) rotateY(2deg) scale(1.03);
  -webkit-filter: drop-shadow(-15px -15px 20px #00000012);
  filter: drop-shadow(-15px -15px 20px #00000012);
}
.headFullContent.productDisplay .list .product .info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.headFullContent.productDisplay .list .product .info img {
  width: 30px;
  height: 30px;
}
.headFullContent.productDisplay .list .product h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
  color: black;
  margin-bottom: 20px;
}
.headFullContent.productDisplay .list .product h2 span {
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  color: black;
}
.headFullContent.productDisplay .list .product .info p {
  font-size: 14px;
  font-weight: 500;
  color: white;
  width: min-content;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: capitalize;
  margin: auto 0 0 0 !important;
}
.headFullContent.productDisplay .list .product h3 {
  font-size: 16px;
  font-weight: 400;
  color: black;
  margin-top: 10px;
}
.headFullContent.productDisplay .list .product h3 span {
  font-size: 14px;
  font-weight: 400;
  color: red;
  margin-left: 8px;
  text-decoration: line-through;
  display: inline-block;
  transform: translateY(-0.1rem);
}
.headFullContent.productDisplay .list .product button {
  height: calc(30px + 0.5rem);
  width: 100%;
  box-shadow: 0 0 0 var(--main-color-1-light);
  margin-top: 10px;
}

.toggleMute {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;

  font-size: 1rem;
  font-weight: 300;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.background {
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;

  text-align: center;
}
.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  color: transparent;
}
.background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background div {
  width: 100%;
  height: 100%;
}

.loadingDiv {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingDiv h2 {
  color: transparent !important;
}
.loadingDiv h3 {
  color: transparent !important;
}
.loadingDiv p {
  color: transparent !important;
}
.loadingDiv img {
  display: none;
}
.loadingContainer {
  pointer-events: none;
  animation: 1.5s loading linear infinite;
}
.loadingContainer * {
  opacity: 0;
}

.types {
  margin: 80px auto 40px auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}
.types .type {
  display: grid;
  grid-template-columns: 180px 1fr;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  background-color: var(--main-color-1);
  cursor: pointer;
  transition: 0.2s;
}
.types .type h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
}
.types .type p {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}
.types .type:hover {
  transform: perspective(800px) rotateX(1deg) rotateY(-1deg) scale(1.02);
  box-shadow: 15px 15px 30px #00000014;
}
.types .type img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

/* ================================================================================== */
/* OLD STYLES ======================================================================= */
/* ================================================================================== */

.home {
  min-height: calc(100vh - 80px);
}

.grayscale {
  filter: grayscale(1);
}

@media only screen and (max-width: 1640px) {
  .types {
    padding: 0 60px;
    gap: 20px;
  }

  .products {
    padding: 20px 40px;
  }
  .products .text {
    padding: 20px;
  }
  .productGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "fire wind" "water earth";
    gap: 20px;
    padding: 20px;
  }

  .headFullContent.productDisplay .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
  }
  .headFullContent.productDisplay .list .product {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  }
}
@media only screen and (max-width: 1400px) {
  .types {
    gap: 30px;
  }
  .types .type {
    grid-template-columns: 140px 1fr;
  }
  .types .type h2 {
    font-size: 20px;
  }
  .types .type p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  .productsCont {
    grid-template-columns: 400px auto;
    gap: 20px;
  }
  .headFullContent {
    padding: 3rem;
    max-width: 66%;
  }
  .headFullContent h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 115%;
  }
  .headFullContent a {
    text-decoration: none;
    cursor: pointer;
  }
  .headFullContent p {
    font-size: 0.9rem;
  }
  .headFullContent .actionArea a {
    font-size: 0.9rem;
  }
  .types {
    gap: 20px;
  }
  .types .type {
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .productsCont {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .types {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 840px) {
  .productGrid .type img {
    width: 70px;
    height: 70px;
  }
}
@media only screen and (max-width: 740px) {
  .headFull {
    aspect-ratio: unset;
    width: 100%;
    height: 75vh;
    height: 80dvh;
  }
  .headFullContent {
    padding: 6rem 2rem;
    max-width: 100%;
    align-items: flex-end;

    background-image: linear-gradient(transparent, #0000002c, #000);
  }
  .headFullContent .bottom {
    width: 100%;
  }
  .toggleMute {
    position: absolute;
    top: 1rem;
    bottom: unset;
    right: 1rem;
    font-size: 0.8rem;
  }

  .headFullContent .actionArea .secondaryBtn {
    background-color: #ffffff0d;
  }

  .types {
    padding: 0 20px;
  }

  .products {
    padding: 20px 0;
  }
  .productGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "fire wind" "water earth";
    gap: 20px;
  }
  .productGrid .type {
    aspect-ratio: unset;
  }
  .headFullContent.productDisplay .list {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .headFullContent.productDisplay .list {
    grid-template-columns: 1fr;
  }

  .headFullContent.productDisplay .list .product {
    grid-template-columns: 1fr 4fr;
  }
  .headFullContent.productDisplay .list .product img {
    aspect-ratio: 1/2;
  }
  .headFullContent.productDisplay .list .product .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 0;
  }
  .headFullContent.productDisplay .list .product .info img {
    width: 30px;
    height: 30px;
  }
  .headFullContent.productDisplay .list .product h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
  }
  .headFullContent.productDisplay .list .product h3 {
    font-size: 1rem;
    font-weight: 400;
    color: black;
    margin-top: 0.25rem;
  }
  .headFullContent.productDisplay .list .product h3 span {
    font-size: 0.9rem;
    font-weight: 400;
    color: red;
    margin-left: 8px;
    text-decoration: line-through;
    display: inline-block;
    transform: translateY(-0.1rem);
  }
  .headFullContent.productDisplay .list .product .info p {
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
    width: min-content;
    padding: 2px 8px;
    border-radius: 4px;
    margin-top: 0.5rem;
    text-transform: capitalize;
  }
  .headFullContent.productDisplay .list .product button {
    height: calc(30px + 0.5rem);
    width: 100%;
    box-shadow: 0 0 0 var(--main-color-1-light);
  }

  .products .text h1 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    margin-bottom: 0.8rem;
  }
  .products .text p {
    font-size: 0.8rem;
    margin-bottom: 1.6rem;
  }
  .products .text a {
    padding: 0.7rem 1.4rem 0.7rem 1.6rem;
    font-size: 0.8rem;
  }
  .products .text a img {
    width: 17px;
    height: 17px;
  }
  .productGrid .type {
    font-size: 0.8rem;
  }

  .headFullContent {
    padding: 4rem 1rem;
  }
  .headFullContent h1 {
    font-size: 1.7rem;
  }
  .headFullContent p {
    font-size: 0.8rem;
  }
  .headFullContent .actionArea {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }
  .headFullContent .actionArea a {
    font-size: 0.8rem;
    width: 100%;
  }
}
@media only screen and (max-width: 330px) {
  .products {
    margin: 40px 0;
    padding: 20px 0;
  }
  .productGrid {
    grid-template-columns: 1fr;
    grid-template-areas: "fire" "wind" "water" "earth";
    gap: 20px;
  }
}
</style>
