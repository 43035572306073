<template>
  <div class="wrapper">
    <CheckoutProgress class="checkoutBar" :step="currentStep" />
    <div class="top">
      <h1>Dostawa</h1>
      <p>Uzupełnienij poniższy formularz aby przejść do płatności.</p>
    </div>
    <div class="grid">
      <div class="form">
        <div class="section largeMargin">
          <h2 class="title">Dane odbiorcy</h2>
          <p class="description">Na podany adres email będą przez nas wysyłane wiadomości dotyczące zamówenia i jego statusu.</p>
          <div class="inputSection">
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent short" @userInput="(data) => handleInput('name', data)" fieldName="imię" :error="errors.name" :default="data.name" />
              <CheckoutTextInput class="inputComponent medium" @userInput="(data) => handleInput('surname', data)" fieldName="nazwisko" :error="errors.surname" :default="data.surname" />
            </div>
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent long" @userInput="(data) => handleInput('email', data)" fieldName="email" :error="errors.email" :default="data.email" />
              <CheckoutSpecificInput class="inputComponent short" @userInput="(data) => handleInput('number', data)" fieldName="numer telefonu" :inputs="['+', '4', '8', ' ', '', '', '', ' ', '', '', '', ' ', '', '', '']" :onlyNumbers="true" :error="errors.number" :default="String(data.number)" />
            </div>
          </div>
        </div>
        <div class="section">
          <h2 class="title">Rodzaj rachunku</h2>
          <p class="description">Wybierz rodzaj rachunku do wystawienia za zamówienie.</p>
          <CheckoutMultipleChoice
            @userInput="(data) => handleInput('typeOfReceipt', data.toLowerCase())"
            :options="[
              { value: 'paragon', display: { main: 'Paragon', secondary: '' } },
              { value: 'faktura', display: { main: 'Faktura', secondary: '' } },
            ]"
            :error="errors.typeOfReceipt"
            default="paragon"
          />
        </div>
        <div class="section largeMargin" v-if="data.typeOfReceipt === 'paragon'">
          <h2 class="title">Adres odbiorcy</h2>
          <p class="description">Uzupełnij pola danymi odbiorcy.</p>
          <div class="inputSection">
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent extraLong" @userInput="(data) => handleInput('paragonAddress', data)" fieldName="ulica i numer budynku/lokalu" :error="errors.paragonAddress" :default="data.paragonAddress" />
            </div>
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent short" @userInput="(data) => handleInput('paragonCity', data)" fieldName="miasto" :error="errors.paragonCity" :default="data.paragonCity" />
              <CheckoutSpecificInput class="inputComponent medium" @userInput="(data) => handleInput('paragonPostalCode', data)" fieldName="kod pocztowy" :inputs="['', '', '-', '', '', '']" :onlyNumbers="true" :error="errors.paragonPostalCode" :default="String(data.paragonPostalCode)" />
            </div>
          </div>
        </div>
        <div class="section largeMargin" v-if="data.typeOfReceipt === 'faktura'">
          <h2 class="title">Dane i adres firmy</h2>
          <p class="description">Prosimy uzupełnić pola danymi firmy.</p>
          <div class="inputSection">
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent medium" @userInput="(data) => handleInput('fakturaCompanyName', data)" fieldName="nazwa firmy" :error="errors.fakturaCompanyName" />
              <CheckoutSpecificInput class="inputComponent short" @userInput="(data) => handleInput('fakturaNIP', data)" fieldName="numer NIP" :inputs="['', '', '', '', '', '', '', '', '', '']" :onlyNumbers="true" :error="errors.fakturaNIP" :default="String(data.fakturaNIP)" />
            </div>
          </div>
          <div class="inputSection">
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent extraLong" @userInput="(data) => handleInput('fakturaAddress', data)" fieldName="ulica i numer budynku/lokalu" :error="errors.fakturaAddress" />
            </div>
            <div class="inputRow">
              <CheckoutTextInput class="inputComponent short" @userInput="(data) => handleInput('fakturaCity', data)" fieldName="miasto" :error="errors.fakturaCity" />
              <CheckoutSpecificInput class="inputComponent medium" @userInput="(data) => handleInput('fakturaPostalCode', data)" fieldName="kod pocztowy" :inputs="['', '', '-', '', '', '']" :onlyNumbers="true" :error="errors.fakturaPostalCode" :default="String(data.fakturaPostalCode)" />
            </div>
          </div>
        </div>
        <div class="section delivery">
          <h2 class="title">Forma wysyłki</h2>
          <p class="description">Wybierz pasującą formę wysyłki z listy.</p>
          <CheckoutMultipleChoice @userInput="(data) => this.changeDeliveryOption(data)" :options="this.deliveryOptions" :error="errors.typeOfReceipt" />
          <!-- <div class="deliveryOptions">
            <div @click="this.changeDeliveryOption('paczkomat')" :class="['option', this.data.typeOfDelivery === 'paczkomat' && 'active']">
              <h3>InPost do paczkomatu</h3>
              <p>Cena: {{ fees.paczkomat ? `${fees.paczkomat}`.slice(0, -2) + "," + `${fees.paczkomat}`.slice(-2) + "zł" : "0zł" }}</p>
            </div>
            <div @click="this.changeDeliveryOption('kurier')" :class="['option', this.data.typeOfDelivery === 'kurier' && 'active']">
              <h3>InPost kurierem na wskazany adres</h3>
              <p>Cena: {{ fees.kurier ? `${fees.kurier}`.slice(0, -2) + "," + `${fees.kurier}`.slice(-2) + "zł" : "0zł" }}</p>
            </div>
            <div @click="this.changeDeliveryOption('odbiór')" :class="['option', this.data.typeOfDelivery === 'odbiór' && 'active']">
              <h3>Odbiór własny na Ursynowie*</h3>
              <p>Cena: {{ fees.odbiór ? `${fees.odbiór}`.slice(0, -2) + "," + `${fees.odbiór}`.slice(-2) + "zł" : "0zł" }}</p>
            </div>
          </div> -->
          <div class="errorIsolated" v-show="errors.typeOfDelivery.message" :style="`background-Color: ${errors.typeOfDelivery.type === 'warning' ? '#efa25f' : '#ef5f5f'}`">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill: none; stroke: #fff; stroke-miterlimit: 10; stroke-width: 32px" />
              <polyline points="220 220 252 220 252 336" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px" />
              <line x1="208" y1="340" x2="296" y2="340" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 32px" />
              <path style="fill: #fff" d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z" />
            </svg>
            <p>{{ errors.typeOfDelivery.message }}</p>
          </div>
        </div>
        <div class="section largeMargin" v-show="this.data.typeOfDelivery">
          <div class="deliveryDetails">
            <div class="variant" v-show="this.data.typeOfDelivery === 'paczkomat'">
              <h2 class="title">Wprowadź identyfikator paczkomatu, do którego mamy wysłać przesyłkę.</h2>
              <p class="description">Przykładowy identyfikator: LOD05N; wybierz swój paczkomat na mapie lub znajdź kod na <a href="https://geowidget.inpost.pl " target="_blanc">stronie InPost</a>.</p>
              <CheckoutTextInput class="inputComponent full" @userInput="(data) => handleInput('inPostId', data)" fieldName="identyfikator" :error="errors.inPostId" :default="data.inPostId" />
              <inpost-geowidget
                class="geoWidget"
                :onpoint="handlePointSelect"
                token="eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwNDAxMjM2ODksImlhdCI6MTcyNDc2MzY4OSwianRpIjoiYjU1MTRmMmUtZjFkYi00ZWM4LWFmMWQtMWQ5MmE2NzAwODI2IiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTphWkoyUHNBa0dGYThLRmlZNTUzTC0yblBLV2pmR3JwWVVoWDd2MjJoUWNVIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiOTQ2NTVjOWEtYmFjZS00MzJkLTgxMWQtZTkzMzQzNDAzMzFhIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6Ijk0NjU1YzlhLWJhY2UtNDMyZC04MTFkLWU5MzM0MzQwMzMxYSIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6Ijc4YTMzMTkyLWY4MzUtNDgyMy05OWUxLTI0NDcyNDEyYmMyNyJ9.g444NYeb8z3N8nW2BHUDF3hqdhC2Mbhl1vCYSFEGsMYYTcU9AGlPLOrDP6C7ZLjCz3F6pjIl_mNRyKOZuf67uDbtJrC-FqHHvJBGPSW2t5Bna19-m70DdzC08_GXdx_cBYelQWzRi3vWsd_AJ2eK4o44W4giFwtu2377EBhj8JUrZvMZ8oc5KsBmnhZ6iOV--_2qs2aACQuVea_gZxXfFl-pB0Ie3GeDZJ-hVTI7_lu-UuJ6_Bf9FAHtUbN5UOo7qwc84rq4G3Qo0Sbh-i_hB6UmXnG5Xij75Pm92DJ1jGokzgxYXKYKOsw-UACSrAXS9IC78zu6KeRJHzOBUgQHPA"
                language="pl"
                config="parcelCollect"
              ></inpost-geowidget>
            </div>

            <div class="variant" v-show="this.data.typeOfDelivery === 'kurier'">
              <h2 class="title">Miejsce wysyłki zamówienia</h2>
              <p class="description">Wybierz adres, na który zostanie wysłana paczka.</p>
              <div class="askSeperate">
                <CheckoutMultipleChoice
                  @userInput="(choice) => (data.seperateDelivery = choice === 'inny')"
                  :options="[
                    { value: 'podany', display: { main: 'Podany powyżej', secondary: '' } },
                    { value: 'inny', display: { main: 'Inny', secondary: '' } },
                  ]"
                  :error="errors.seperateDelivery"
                  default="podany"
                />
              </div>

              <div v-show="data.seperateDelivery" class="seperateDeliveryData">
                <p class="description">Wprowadź inny adres, na który zostanie wysłane zamówienie.</p>
                <div class="inputRow">
                  <CheckoutTextInput class="inputComponent long" @userInput="(data) => handleInput('deliveryRecepient', data)" fieldName="imię i nazwisko" :error="errors.deliveryRecepient" />
                </div>
                <div class="inputRow">
                  <CheckoutTextInput class="inputComponent extraLong" @userInput="(data) => handleInput('deliveryAddress', data)" fieldName="ulica i numer budynku/lokalu" :error="errors.deliveryAddress" />
                </div>
                <div class="inputRow">
                  <CheckoutTextInput class="inputComponent short" @userInput="(data) => handleInput('deliveryCity', data)" fieldName="miasto" :error="errors.deliveryCity" />
                  <CheckoutSpecificInput class="inputComponent short" @userInput="(data) => handleInput('deliveryPostalCode', data)" fieldName="kod pocztowy" :inputs="['', '', '-', '', '', '']" :onlyNumbers="true" :error="errors.deliveryPostalCode" />
                </div>
              </div>
            </div>
            <div class="variant" v-show="this.data.typeOfDelivery === 'odbiór'">
              <h2>Odbiór własny - szczegóły</h2>
              <p class="deliveryMessage">Odbiór własny odbywa się po uprzednim ustaleniu miejsca i terminu - zadzwoń +48 693 378 357</p>
            </div>
          </div>
        </div>
        <div class="arrowToCheckout">
          <!-- prettier-ignore -->
          <svg viewBox="0 0 581 127" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 98.5C236.5 7 292.5 81 580 52.5M580 52.5L496 126.5M580 52.5L483.5 1" /></svg>
        </div>
      </div>
      <div class="cart" ref="cartDiv">
        <div class="sticky">
          <div class="section cartStick">
            <div class="cover"></div>
            <h2 class="title">Koszyk</h2>
            <p class="description">Aktualna zawartość Twojego koszyka.</p>
            <div class="discountInfo">
              <h1>Darmowa dostawa</h1>
              <p>
                Oferta dla zamówień gdzie wartość koszyka przekracza 150zł.
                <span v-show="sum - discountedValue.cart < 15000 && loaded"><router-link to="/products">Dodaj produkty.</router-link></span>
                <span v-show="sum - discountedValue.cart >= 15000 && loaded">Darmowy transport aktywny.</span>
              </p>
            </div>
            <div class="products">
              <ProductComponent class="product" v-for="(item, i) of list" :key="i" :data="item" :showQuantity="true" :showLoading="!loaded"></ProductComponent>
              <ProductComponent class="product" :data="deliveryItem" :showQuantity="false" v-show="data.typeOfDelivery"></ProductComponent>
              <div class="sum" v-if="cart.length !== 0">
                <div class="info">
                  <p class="text">Do zapłaty</p>
                  <p class="number" :style="!loaded && `animation: 1.5s loading linear infinite`">
                    <span v-show="discountedValue.total > 0">{{ loaded && `${sum}`.slice(0, -2) + "," + `${sum}`.slice(-2) + "zł" }}</span
                    >{{ loaded ? priceString(sum - discountedValue.total) : "Calculating" }}
                  </p>
                </div>

                <button @click="goPay('paypo')" v-show="buttonStatus === 'ready'" class="secondary">
                  <div class="version ready">
                    Do płatności
                    <img src="/assets/img/Paypo_logo_black.png" alt="PayPo" />
                  </div>
                </button>

                <button @click="goPay('przelewy24')" v-show="buttonStatus === 'ready'">
                  <div class="version ready">
                    Do płatności
                    <img src="/assets/img/Przelewy24_logo.png" alt="Przelewy24" />
                  </div>
                </button>

                <button v-show="buttonStatus !== 'ready'" style="pointer-events: none">
                  <div class="version error" v-show="buttonStatus === 'error'">Wystąpił błąd</div>
                  <div class="version wait" v-show="buttonStatus === 'loading'">Proszę czekać...</div>
                  <div class="version success" v-show="buttonStatus === 'success'">Przekierowuję...</div>
                </button>

                <div class="errorMainDiv" :style="`padding: ${error ? '4px 8px' : 0}; background-color: ${ok ? '#dcffd5' : '#ffd5d5'}`">
                  <p :style="`max-height=${error ? '100px' : 0}; color: ${ok ? '#46ba2c' : '#da4c4c'}`">{{ error }}</p>
                </div>

                <div class="agreement">
                  <input type="checkbox" @input="(e) => handleInput('agreementP24', e.target.checked)" />
                  <p>Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin" target="_blank">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik" target="_blank">obowiązkiem informacyjnym</a> serwisu Przelewy24</p>
                </div>

                <div class="agreement">
                  <div></div>
                  <p>Zamawiając akceptujesz <a href="/statute" target="_blank">regulamin</a> i <a href="/privacy" target="_blank">politykę prywatności</a> MyAURA.</p>
                </div>

                <div class="agreement" v-for="agreement of this.agreements" :key="agreement.name">
                  <div></div>
                  <p>Zamawiając akceptujesz <a :href="agreement.document" target="_blank">regulamin</a> promocji "{{ agreement.name }}".</p>
                </div>

                <div class="errorIsolated" v-show="errors.agreementP24.message" :style="`background-Color: ${errors.agreementP24.type === 'warning' ? '#efa25f' : '#ef5f5f'}`">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill: none; stroke: #fff; stroke-miterlimit: 10; stroke-width: 32px" />
                    <polyline points="220 220 252 220 252 336" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px" />
                    <line x1="208" y1="340" x2="296" y2="340" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 32px" />
                    <path style="fill: #fff" d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z" />
                  </svg>
                  <p>{{ errors.agreementP24.message }}</p>
                </div>
              </div>
              <div class="noItems" v-if="cart.length === 0">
                <h3>&times;</h3>
                <p>Twój koszyk jest pusty</p>
              </div>
            </div>
          </div>
          <div class="section largeMargin discountSection">
            <h2 class="title">Kod rabatowy</h2>
            <p class="description">Wprowadź kod rabatowy, jeśli go posiadasz.</p>
            <div class="display" v-if="this.discount">
              <p>{{ this.discount.name }}</p>
              <button @click="this.resetDiscount()">&times;</button>
            </div>
            <div class="add" v-if="!this.discount">
              <CheckoutTextInput class="inputComponent extraLong" @userInput="(data) => handleInput('discountInput', data)" fieldName="kod" :error="errors.discountInput" />
              <button class="applyDiscount" @click="applyDiscount(true)">Dodaj</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CheckoutFloatingButton :class="['floatingButton', !floatingButton && 'hidden']" @scrollToButton="scrollToButton" :data="{ sum, loaded, discountedValue }" />
  </div>
</template>

<script>
import appStore from "@/stores/appStore.js";
import CheckoutProgress from "../components/CheckoutProgress.vue";
import CheckoutTextInput from "../components/CheckoutTextInput.vue";
import CheckoutSpecificInput from "../components/CheckoutSpecificInput.vue";
import CheckoutMultipleChoice from "../components/CheckoutMultipleChoice.vue";
import CheckoutFloatingButton from "../components/CheckoutFloatingButton.vue";
import ProductComponent from "../components/ProductComponent.vue";

export default {
  data() {
    return {
      currentStep: 1,
      buttonStatus: "loading",
      floatingButton: false,

      geoWidgetDataLoaded: false,

      loaded: false,
      sum: 0,
      list: [],
      ok: false,
      error: "",
      errors: {
        // COMMON DATA
        email: {
          type: "",
          message: "",
        },
        name: {
          type: "",
          message: "",
        },
        surname: {
          type: "",
          message: "",
        },
        number: {
          type: "",
          message: "",
        },

        // TYPE OF TRANSACTION
        typeOfReceipt: {
          type: "",
          message: "",
        },

        // IF PARAGON
        paragonAddress: {
          type: "",
          message: "",
        },
        paragonPostalCode: {
          type: "",
          message: "",
        },
        paragonCity: {
          type: "",
          message: "",
        },

        // IF FAKTURA
        fakturaCompanyName: {
          type: "",
          message: "",
        },
        fakturaNIP: {
          type: "",
          message: "",
        },
        fakturaAddress: {
          type: "",
          message: "",
        },
        fakturaPostalCode: {
          type: "",
          message: "",
        },
        fakturaCity: {
          type: "",
          message: "",
        },

        // TYPE OF DELIVERY
        typeOfDelivery: {
          type: "",
          message: "",
        },

        // IF PACZKOMAT
        inPostId: {
          type: "",
          message: "",
        },

        // IF KURIER
        seperateDelivery: {
          type: "",
          message: "",
        },
        // and seperate delivery
        deliveryRecepient: {
          type: "",
          message: "",
        },
        deliveryAddress: {
          type: "",
          message: "",
        },
        deliveryPostalCode: {
          type: "",
          message: "",
        },
        deliveryCity: {
          type: "",
          message: "",
        },

        discountInput: {
          type: "",
          message: "",
        },

        agreementP24: {
          type: "",
          message: "",
        },
      },
      data: {
        // COMMON DATA
        email: "",
        name: "",
        surname: "",
        number: "",

        // TYPE OF TRANSACTION
        typeOfReceipt: "paragon",

        // IF PARAGON
        paragonAddress: "",
        paragonPostalCode: "",
        paragonCity: "",

        // IF FAKTURA
        fakturaCompanyName: "",
        fakturaNIP: "",
        fakturaAddress: "",
        fakturaPostalCode: "",
        fakturaCity: "",

        // TYPE OF DELIVERY
        typeOfDelivery: "",

        // IF PACZKOMAT
        inPostId: "",

        // IF KURIER
        seperateDelivery: false,
        // and seperate delivery
        deliveryRecepient: "",
        deliveryAddress: "",
        deliveryPostalCode: "",
        deliveryCity: "",

        // CHOOSE TO SAVE DATA TO ACCOUNT
        saveData: true,

        // DISCOUNT CODE
        discountInput: "",

        // AGREEMENTS
        agreementP24: false,
      },

      deliveryItem: {
        product: {
          preview:
            "data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQwMCIgd2lkdGg9IjQwMCIgdmlld0JveD0iLTYgLTEwIDYwIDYwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgIDxwYXRoIHN0cm9rZT0iI2ZjYzk1OSIgZD0iTTE4IDI3TDI0IDI5QzI0IDI5IDM5IDI2IDQxIDI2QzQzIDI2IDQzIDI4IDQxIDMwQzM5IDMyIDMyIDM4IDI2IDM4QzIwIDM4IDE2IDM1IDEyIDM1SDIiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiAvPgogICAgIDxwYXRoIHN0cm9rZT0iI2ZjYzk1OSIgZD0iTTIgMjNDNCAyMSA4IDE4IDEyIDE4QzE2IDE4IDI1LjUgMjIgMjcgMjRDMjguNSAyNiAyNCAyOSAyNCAyOU0xNCAxMlY0QzE0IDMuNDY5NTcgMTQuMjEwNyAyLjk2MDg2IDE0LjU4NTggMi41ODU3OUMxNC45NjA5IDIuMjEwNzEgMTUuNDY5NiAyIDE2IDJINDBDNDAuNTMwNCAyIDQxLjAzOTEgMi4yMTA3MSA0MS40MTQyIDIuNTg1NzlDNDEuNzg5MyAyLjk2MDg2IDQyIDMuNDY5NTcgNDIgNFYyMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+CiAgICAgPHBhdGggc3Ryb2tlPSIjZmNjOTU5IiBkPSJNMjMgMkgzM1YxMUgyM1YyWiIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+Cjwvc3ZnPgo=",
          _id: "_delivery",
          name: "Dostawa",
          price: 0,
        },
        quantity: 1,
        discount: 0,
      },

      fees: {
        paczkomat: 0,
        kurier: 0,
        odbiór: 0,
      },

      discountedValue: {
        // if entire order is discounted
        order: 0,
        // if the delivery has a discount
        delivery: 0,
        // if the products have a discount
        cart: 0,
        // if products and delivery have a discount
        total: 0,
      },
      discount: null,

      agreements: [],

      deliveryOptions: [
        { value: "paczkomat", display: { main: "InPost do Paczkomatu", secondary: this.priceString(appStore.fees.paczkomat) } },
        { value: "kurier", display: { main: "InPost kurierem na wskazany adres", secondary: this.priceString(appStore.fees.kurier) } },
        { value: "odbiór", display: { main: "Odbiór własny na Ursynowie*", secondary: this.priceString(appStore.fees.odbiór) } },
      ],
    };
  },
  props: {
    token: String,
    user: Object,
    cart: Array,
  },
  stores: {
    appStore,
  },
  components: {
    CheckoutProgress,
    CheckoutTextInput,
    CheckoutSpecificInput,
    CheckoutMultipleChoice,
    CheckoutFloatingButton,
    ProductComponent,
  },
  methods: {
    priceString(price) {
      return appStore.priceString(price);
    },
    handlePointSelect(data) {
      try {
        console.log(`Point selected: ${data}`);
        this.data.inPostId = data.name;
      } catch (err) {
        console.log("Error in handlePointSelect", err);
      }
    },
    observerCallback(e) {
      if (e[0].isIntersecting === true) {
        this.floatingButton = false;
      } else {
        this.floatingButton = true;
      }
    },
    scrollToButton() {
      this.$refs.cartDiv.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    setupObserver() {
      const options = {
        rootMargin: "0px",
        threshold: 0.2,
      };

      const observer = new IntersectionObserver(this.observerCallback, options);

      this.observerButton = observer.observe(this.$refs.cartDiv);
    },
    deactivateObserver() {
      return;
    },
    handleResize() {
      const dimensions = document.body.getBoundingClientRect();
      if (dimensions.width <= 700) {
        this.floatingButton = true;
        this.setupObserver();
      } else {
        this.floatingButton = false;
        this.deactivateObserver();
      }
    },
    changeButtonStyle(status) {
      this.buttonStatus = status;
    },
    clearErrors() {
      Object.values(this.errors).forEach((err) => {
        err.type = "";
        err.message = "";
      });
    },
    checkNoError() {
      let noError = true;
      Object.values(this.errors).forEach((error) => {
        if (error.message !== "") noError = false;
      });
      return noError;
    },
    changeDeliveryOption(option) {
      this.data.typeOfDelivery = option;
      this.errors.typeOfDelivery.type = "";
      this.errors.typeOfDelivery.message = "";
      if (this.checkNoError()) {
        this.error = "";
        this.changeButtonStyle("ready");
      }
      this.deliveryItem.product.name = `Dostawa - ${option}`;
      this.prepareList();
    },
    handleInput(field, data) {
      this.errors[field].type = "";
      this.errors[field].message = "";

      this.data[field] = data;
      if (this.checkNoError()) {
        this.error = "";
        this.changeButtonStyle("ready");
      }
    },
    calcShowDiscount(product, quantity) {
      let totalDsc = 0;
      if (this.discount.discountMode === "percentage") {
        product.discount = Math.floor((product.price * this.discount.discountValue) / 100);
        totalDsc = product.discount * quantity;
      } else if (this.discount.discountMode === "constant") {
        product.discount = this.discount.discountValue;
        totalDsc = product.discount * quantity;
      }
      return totalDsc;
    },
    applyDiscountProducts() {
      const dsc = this.discount;
      // discount.name
      if (!dsc.name) return;
      let totalDsc = 0;
      if (dsc.appliesTo.length === 1 && dsc.appliesTo[0].type === "order") {
        if (dsc.discountMode === "percentage") {
          const multiplier = dsc.discountValue / 100;
          dsc.valueSubtracted = Math.floor(this.sum * multiplier);
        } else if (dsc.discountMode === "constant") {
          dsc.valueSubtracted = dsc.discountValue;
        }
        return;
      }
      dsc.appliesTo.forEach((a) => {
        if (a.type === "delivery") {
          if (dsc.discountMode === "percentage") {
            const multiplier = dsc.discountValue / 100;
            this.discountedDelivery = multiplier * appStore.fees[this.data.typeOfDelivery];
          } else if (dsc.discountMode === "constant") {
            this.discountedDelivery = appStore.fees[this.data.typeOfDelivery] - dsc.discountValue;
          }
        }
        this.list.forEach((p) => {
          // return if the product has been discounted in a bundle
          if (p._usedInBundle) return;
          if (p.product.type !== "SET") {
            if (a.type === p.product.productLine && a.element === p.product.element) {
              totalDsc += this.calcShowDiscount(p.product, p.quantity);
            } else if (a.type === p.product.productLine && !a.element) {
              totalDsc += this.calcShowDiscount(p.product, p.quantity);
            } else if (a.element === p.product.element && !a.type) {
              totalDsc += this.calcShowDiscount(p.product, p.quantity);
            }
          } else {
            if (a.type === p.product.name) {
              totalDsc += this.calcShowDiscount(p.product, p.quantity);
            }
          }
        });
      });
      dsc.valueSubtracted = totalDsc;
    },
    resetDiscount(ignorePrepare = false) {
      this.discountedValue = {
        order: 0,
        delivery: 0,
        cart: 0,
        total: 0,
      };
      this.discount = null;
      this.errors.discountInput = {
        type: "",
        message: "",
      };
      if (!ignorePrepare) this.prepareList();
    },
    async applyDiscount(user = false) {
      try {
        if (!this.data.discountInput) {
          if (user === true) {
            this.errors.discountInput = {
              type: "warning",
              message: "Proszę wprowadzić kod zniżkowy.",
            };
          }
          return;
        }

        this.resetDiscount(true);
        const dsc = await this.getDiscount(this.data.discountInput);

        this.errors.discountInput.type = "warning";
        if (!dsc) {
          this.errors.discountInput.message = "Podany kod nie pasuje do żadnej zniżki.";
          return;
        }
        if (new Date().getTime() - new Date(dsc?.startDate).getTime() <= 0) {
          this.error = `Zniżka "${dsc.name}" jeszcze się nie zaczęła.`;
          return;
        }
        if (new Date(dsc?.endDate).getTime() - new Date().getTime() <= 0) {
          this.errors.discountInput.message = `Zniżka "${dsc.name}" jest przeterminowana.`;
          return;
        }
        if (dsc.usesRemaining <= 0) {
          this.errors.discountInput.message = `Zniżka "${dsc.name}" została już wyczerpana.`;
        }
        if (dsc.usesRemaining <= 5) {
          this.errors.discountInput.message = `Zniżka "${dsc.name}" została dodana. Radzimy się pośpieszyć - można ją aktywować jeszcze tylko ${dsc.usesRemaining} razy!`;
          return;
        }

        this.errors.discountInput.type = "success";
        this.errors.discountInput.message = `Zniżka "${dsc.name}" została aktywowana!`;

        this.discount = dsc;
        this.prepareList();
      } catch (err) {
        this.error = err;
      }
    },

    async getDiscount(code) {
      try {
        const res = await fetch(`/api/discounts/${code}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        });
        const data = await res.json();
        return data.data;
      } catch (err) {
        this.ok = false;
        this.error = err;
      }
    },

    async prepareList() {
      const checkoutData = {
        cart: this.cart,
        discount: this.discount,
        delivery: { type: this.data.typeOfDelivery === "" ? null : this.data.typeOfDelivery },
      };
      appStore
        .genereteCartCheckoutData(checkoutData)
        .then((data) => {
          this.loaded = true;
          this.changeButtonStyle("ready");

          this.sum = data.sum;
          this.list = data.items;
          this.discountedValue = data.discount;

          this.deliveryItem.product.price = data.deliveryFee;
          this.deliveryItem.discount = data.discount.delivery;

          this.agreements = [];
          data.bundlesUsed.forEach((bundle) => {
            this.agreements.push({
              name: bundle.name,
              document: bundle.agreement,
            });
          });
        })
        .catch((err) => {
          console.warn(err);
        });

      // Delivery option prices can't be displayed from the appStore for some reason
      // and sometimes don't load when the page is reloaded
      this.deliveryOptions.forEach((option) => {
        if (isNaN(option.display.secondary)) {
          option.display.secondary = this.priceString(appStore.fees[option.value]);
        }
      });
    },
    async goPay(paymentMethod) {
      if (!this.agreementsAccepted()) {
        this.errors.agreementP24.type = "warning";
        this.errors.agreementP24.message = "Proszę zaakceptować wszystkie zgody";
        return;
      }
      this.changeButtonStyle("loading");
      this.data.paymentMethod = paymentMethod;
      this.error = "";
      this.clearErrors();
      let req;
      if (this.discount) {
        this.data.discountCode = this.discount.code;
      } else {
        this.data.discountCode = undefined;
      }
      if (this.token) {
        req = await fetch("/api/orders", {
          method: "POST",
          headers: { "content-type": "application/json", Authorization: `Bearer ${this.token}` },
          body: JSON.stringify(this.data),
        });
      } else {
        delete this.data.saveData;
        this.data.cart = this.cart;

        req = await fetch("/api/orders/guest", {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(this.data),
        });
      }

      const res = await req.json();

      this.ok = false;
      if (req.status === 200) {
        this.changeButtonStyle("success");
        this.ok = true;
        this.error = res.message;
        setTimeout(() => {
          window.dataLayer.push({ event: "purchase" });
          window.location.replace(res.link);
        }, 1000);
      } else if (req.status === 400) {
        this.changeButtonStyle("error");
        if (res.formErrors) {
          Object.entries(res.formErrors).forEach((e) => {
            this.errors[e[0]] = e[1];
          });
        }
        this.error = res.message;
      } else {
        this.changeButtonStyle("error");
        this.error = res.message;
      }
    },
    agreementsAccepted() {
      return this.data.agreementP24;
    },
    setupGeoWidget() {
      document.addEventListener("onpointselect", (event) => alert(event.details.name));
      const head = document.head;

      const styles = document.createElement("link");
      styles.type = "text/css";
      styles.rel = "stylesheet";
      styles.href = "https://geowidget.inpost.pl/inpost-geowidget.css";
      head.appendChild(styles);

      const customScript = document.createElement("script");
      customScript.innerHTML = `function afterPointSelected(point) { alert(point.name) }`;
      document.body.appendChild(customScript);

      const script = document.createElement("script");
      script.defer = true;
      script.src = "https://geowidget.inpost.pl/inpost-geowidget.js";
      head.appendChild(script);
      script.onload = () => {
        this.geoWidgetDataLoaded = true;
      };
    },
  },
  beforeRouteLeave(_, __, next) {
    if (this.ok || window.confirm("Dane nie zostaną zapisane!")) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    document.title = `MyAURA - Podsumowanie zamówienia`;

    this.list = this.cart.map(() => null);

    appStore.fetchDetailedCheckoutData().then(() => {
      this.prepareList();
    });
  },
  mounted() {
    if (this.user) {
      this.data.email = this.user.email;
      this.data.name = this.user.name;
      this.data.surname = this.user.surname;
      this.data.number = this.user.number;
      this.data.address = this.user.address;
      this.data.postalCode = this.user.postalCode;
      this.data.city = this.user.city;
    }

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.changeButtonStyle("loading");

    this.setupGeoWidget();
    document.addEventListener("onpointselect", (e) => console.log(e));
    document.addEventListener("onpoint", (e) => console.log(e));
    document.addEventListener("afterPointSelected", (e) => console.log(e));
  },
  unmounted() {
    this.deactivateObserver();
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    cart: {
      immediate: true,
      deep: true,
      handler() {
        if (this.cart.length) {
          this.loaded = false;
          this.prepareList();
        }
      },
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: calc(var(--nav-height) + 20px);
}
.form {
  padding-bottom: 150px;
}
.arrowToCheckout {
  margin-top: 200px;
  text-align: center;
}
.arrowToCheckout svg {
  stroke: #494949;
  width: 200px;
}

.wrapper {
  max-width: 1640px;
  margin: auto;
  padding: 0 20px 50px 20px;
  min-height: 100vh;
}
.checkoutBar {
  margin: 20px 0;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

.discountSection .display {
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background-color: #eaeaea;
  border-radius: 4px;
  width: fit-content;
  user-select: none;
}
.discountSection .display p {
  font-size: 12px;
  font-weight: 400;
  color: #3e3e3e;
}
.discountSection .display button {
  font-size: 15px;
  font-weight: 400;
  color: #3e3e3e;
  border: none;
  background: transparent;
  cursor: pointer;
}

.discountInfo {
  background-color: #c6f7c6;
  border: 1px solid #85e185;

  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.discountInfo h1 {
  font-size: 15px;
  font-weight: 600;
  color: #3e3e3e;
  line-height: 1;
  margin-bottom: 3px;
}
.discountInfo p,
.discountInfo a {
  font-size: 12px;
  font-weight: 400;
  color: #3e3e3e;
  line-height: 1.25;
}

.floatingButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;

  transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.floatingButton.hidden {
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
}

.errorMainDiv {
  /* background-color: #ffd5d5; */
  border-radius: 0 0 2px 2px;
  user-select: none;
  -webkit-user-select: none;
}
.errorMainDiv p {
  font-size: 0.8rem;
  font-weight: 500;
  /* color: #da4c4c; */
}

.applyDiscount {
  display: block;
  margin: 5px 0 0 auto;

  padding: 6px 16px;
  border-radius: 2px;
  background-color: var(--action-color);
  border: none;
  cursor: pointer;

  font-size: 0.9rem;
  font-weight: 400;
  color: white;
}
.applyDiscount:hover {
  background-color: var(--action-color-hover);
}

.errorIsolated {
  margin-top: 10px;

  display: grid;
  justify-content: left;
  align-items: center;
  grid-template-columns: 1rem auto;
  gap: 8px;

  padding: 4px 8px;
  transition: 0.2s;
  background-color: #ef5f5f;
  border-radius: 2px;
  user-select: none;
  pointer-events: none;
}
.errorIsolated svg {
  width: 1.1rem;
  height: 1.1rem;
}
.errorIsolated p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #ffffff;
}

/* .cartStick {
  position: sticky;
  position: -webkit-sticky;
  z-index: 6;
  top: 90px;
}
.cartStick .cover {
  position: absolute;
  bottom: 100%;
  left: -10px;
  width: calc(100% + 20px);
  height: 20px;
  background-color: rgb(244, 244, 244);
  z-index: -1;
} */

.agreement {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: flex-start;
  gap: 10px;
}
.agreement input {
  margin-top: 2px;
  width: 100%;
  aspect-ratio: 1/1;
}
.agreement p {
  font-size: 0.75rem;
  font-weight: 400;
  color: #5a5a5a;
}
.agreement a {
  color: #677dae;
  text-decoration: underline;
}

.askSeperate {
  max-width: calc(50% - 5px);
}
.seperateDeliveryData {
  margin-top: 10px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 100px;
}

.sum {
  border-top: 1px solid #b9b9b9;
  margin-top: 10px;
  padding-top: 10px;
}
.sum .info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
}
.sum .text {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5a5a5a;
}
.sum .number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #3e3e3e;
  line-height: 1;
  padding: 4px 0;

  display: flex;
  text-align: right;
}
.sum .number span {
  font-size: 0.8rem;
  font-weight: 400;
  color: #c85d5d;
  text-decoration: line-through;

  margin: 0 6px 0 0;
}
.sum button {
  width: 100%;

  border: none;

  font-size: 1.15rem;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
.sum button.secondary {
  margin-bottom: 4px;
}
.sum button .version {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 100%;
  padding: 0.5rem 1.5rem;
  border-radius: 2px;
}
.sum button .ready {
  background-color: var(--action-color);
  cursor: pointer;
}
.sum button.secondary .ready {
  color: #3d3d3d;
  font-weight: 500;
  background-color: #eee;
  cursor: pointer;
}
.sum button .ready:hover {
  background-color: var(--action-color-hover);
}
.sum button.secondary .ready:hover {
  background-color: #ddd;
}

.sum button .wait {
  background-color: #393939;
  pointer-events: none;
  cursor: wait;
}
.sum button .wait:hover {
  background-color: #3d3d3d;
}
.sum button .error {
  background-color: #ef5f5f;
  cursor: not-allowed;
}
.sum button .error:hover {
  background-color: #e87777;
}
.sum button .success {
  background-color: #438d38;
  cursor: wait;
  pointer-events: none;
}
.sum button .success:hover {
  background-color: #57a14b;
}

.sum button img {
  display: inline;
  height: 1.15rem;
  aspect-ratio: 4/1;
  object-fit: contain;
}

.noItems {
  text-align: center;
  padding: 30px 0;
}
.noItems h3 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 100;
  color: #808080;
}
.noItems p {
  font-size: 1rem;
  font-weight: 400;
  color: #969595;
}

.deliveryOptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-top: 5px;
}
.deliveryOptions div {
  background-color: #f4f4f4;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}
.deliveryOptions .active {
  background-color: #ffffff;
  border: 1px solid #4a4a4a;
}
.deliveryOptions div:hover {
  background-color: #fafafa;
  border: 1px dashed #4a4a4a;
}
.deliveryOptions .active:hover {
  background-color: #ffffff;
  border: 1px solid #4a4a4a;
}
.deliveryOptions h3 {
  font-size: 0.9rem;
  font-weight: 500;
  color: #3a3a3a;
}
.deliveryOptions p {
  font-size: 0.75rem;
  font-weight: 400;
  color: #434343;
}

/* .deliveryDetails {
  border-top: 1px solid #b9b9b9;
  margin-top: 20px;
  padding-top: 15px;
} */
.deliveryDetails h2 {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 600;
  color: #3e3e3e;
  margin-bottom: 4px;
}
.deliveryDetails p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 15px;
}

.deliveryDetails .deliveryMessage {
  font-size: 0.8rem;
  font-weight: 400;
  color: #434343;
  margin: 15px 0 0 0;
}

.section {
  background: white;
  box-shadow: 0 0 10px #00000011;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;
}
.section.largeMargin {
  margin-bottom: 60px;
}
.section .title {
  font-size: 1rem;
  font-weight: 700;
  color: #3e3e3e;
}
.section .description {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 15px;
}

.inputRow {
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
}

.inputSection {
  margin-bottom: 20px;
}
.inputSection:last-of-type {
  margin-bottom: 0;
}

.inputComponent.short {
  width: 100%;
  max-width: 240px;
}
.inputComponent.medium {
  width: 100%;
  max-width: 320px;
}
.inputComponent.long {
  width: 100%;
  max-width: 420px;
}
.inputComponent.extraLong {
  width: 100%;
}

.products {
  padding: 5px 0 0 0;
  margin-bottom: -5px;
}
.deliveryProduct {
  display: grid;
  grid-template-columns: 60px auto;
  gap: 10px;
  padding: 0 0 0.5rem 0;
}
.deliveryProduct .imageProd {
  height: 60px;
  width: 100%;
  object-fit: contain;
}
.deliveryProduct .name {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3a3a3a;
  text-decoration: none;
  margin-bottom: 2px;
}
.deliveryProduct .price {
  font-size: 12px;
  font-weight: 400;
  color: #454545;
}

.deliveryIcon {
  margin: auto;
  max-width: 60%;
  stroke: var(--main-color-1);
}

.top {
  margin: 0 0 40px 0;
}
.top h1 {
  font-size: 2rem;
  color: #232323;
}
.top p {
  font-size: 1rem;
  color: #555555;
}

.geoWidget {
  display: block;
  height: 400px;
  margin-top: 20px;
  border: 1px solid #b9b9b9;
}

@media only screen and (max-width: 1300px) {
  .grid {
    grid-template-columns: 1fr 300px;
    gap: 60px;
  }
}

@media only screen and (max-width: 1150px) {
  .section.largeMargin {
    margin-bottom: 30px;
  }
  .grid {
    grid-template-columns: 100%;
    gap: 30px;
  }
  .sticky {
    position: static;
  }
  .form {
    padding-bottom: 0;
  }
  .arrowToCheckout {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .checkoutBar {
    margin: 20px 0 0 0;
  }
  .top {
    margin: 0 0 30px 0;
  }
  .top h1 {
    font-size: 1.4rem;
    color: #232323;
  }
  .top p {
    font-size: 0.8rem;
    color: #555555;
  }

  .askSeperate {
    max-width: 100%;
  }

  .inputRow {
    flex-direction: column;
    gap: 0;
  }

  .deliveryOptions {
    grid-template-columns: 100%;
  }

  .deliveryOptions h3 {
    font-size: 0.8rem;
  }
  .deliveryOptions p {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 400px) {
  .wrapper {
    padding: 0 15px 50px 15px;
  }

  .delivery .error {
    grid-template-columns: 1rem auto;
    gap: 8px;

    padding: 4px 8px;
  }
  .delivery .error svg {
    width: 1rem;
    height: 1rem;
  }
  .delivery .error p {
    font-size: 0.8rem;
  }

  .section {
    padding: 15px;
  }
  .section .title {
    font-size: 1rem;
  }
  .section .description {
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  .sum .text {
    font-size: 0.7rem;
  }
  .sum .number {
    font-size: 1.1rem;
  }
  .sum .number span {
    font-size: 0.7rem;
  }

  .agreement {
    margin-top: 10px;
    font-size: 0.65rem;
  }

  .deliveryProduct {
    grid-template-columns: 50px auto;
  }
  .deliveryProduct .imageProd {
    height: 50px;
  }
  .deliveryProduct .name {
    font-size: 0.8rem;
    line-height: 17px;
  }
  .deliveryProduct .price {
    font-size: 0.7rem;
  }

  .inputComponent.short {
    width: 100%;
    max-width: unset;
  }
  .inputComponent.medium {
    width: 100%;
    max-width: unset;
  }
  .inputComponent.long {
    width: 100%;
    max-width: unset;
  }
}
</style>
