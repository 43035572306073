<template>
  <div>
    <div class="pBox">
      <div class="placeholder" v-if="showLoading">
        <div class="imageProd" style="animation: 1.5s loading linear infinite" />
        <div class="details">
          <p class="name" style="animation: 1.5s loading linear infinite">&nbsp;</p>
          <p class="price" style="animation: 1.5s loading linear infinite">&nbsp;</p>
        </div>
      </div>
      <div class="pCont" v-if="!showLoading">
        <img class="imageProd" :src="data.product.preview" alt="Preview" />
        <div class="details">
          <router-link class="name" :to="data.product._id.startsWith('SET') ? `/set-creator?set=${data.product._id}` : `/products/${data.product._id}`">
            <h2>
              {{ data.product.name }}
            </h2>
          </router-link>
          <p :class="['price', data.discount > 0 && 'discounted']">
            <span class="default">{{ priceString(data.product.price, data.discount <= 0) }}</span> <span class="dsc" v-show="data.discount > 0">→ {{ priceString(data.product.price - data.discount) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appStore from "@/stores/appStore.js";

export default {
  data() {
    return {
      name: "",
      nameIsArray: false,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    showQuantity: {
      type: Boolean,
    },
    showLoading: {
      type: Boolean,
    },
  },
  methods: {
    priceString(price, isLastPrice = true) {
      let string = appStore.priceString(price);
      if (this.showQuantity && isLastPrice) {
        string += ` (${this.data.quantity}x)`;
      }
      return string;
    },
  },
  created() {
    if (this.data === null) return;
    this.name = appStore.analyzeUpdateName(this.data.product.name);
    this.nameIsArray = Array.isArray(this.name);
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        if (this.data === null) return;
        this.name = appStore.analyzeUpdateName(this.data.product.name);
        this.nameIsArray = Array.isArray(this.name);
      },
    },
  },
};
</script>

<style scoped>
.pBox {
  container-type: inline-size;
  width: 100%;
}
.pBox .pCont,
.pBox .placeholder {
  width: 100%;
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 10px;
  padding: 0;
}
.pBox .imageProd {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.pBox .details {
  display: flex;
  flex-direction: column;
}
.pBox a {
  text-decoration: none;
}
.pBox h2 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #3a3a3a;
  margin-bottom: 4px;
}
.pBox h2 span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #4d4d4d;
}
.pBox .price {
  margin-top: 4px;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #3a3a3a;
}
.pBox .discounted .default {
  text-decoration: line-through;
}
.pBox .discounted .dsc {
  text-decoration: none;
}

.pBox .placeholder {
  padding: 0 0 0.5rem 0;
}
.pBox .placeholder .imageProd {
  height: 50px;
}
.pBox .placeholder .price {
  font-size: 12px;
}

@container (max-width: 600px) {
  .pBox .pCont,
  .pBox .placeholder {
    width: 100%;
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 10px;
    padding: 0;
  }
  .pBox .imageProd {
    height: 60px;
    width: 100%;
    object-fit: contain;
  }
  .pBox .details {
    display: flex;
    flex-direction: column;
  }
  .pBox .name {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #3a3a3a;
    text-decoration: none;
  }
  .pBox h2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #3a3a3a;
    margin-bottom: 2px;
    text-decoration: none;
  }
  .pBox h2 span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #4d4d4d;
  }

  .pBox .price {
    margin: 2px 0 0 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    color: #3a3a3a;
    /* text-align: right; */
  }
  .pBox .discounted .default {
    text-decoration: line-through;
  }
  .pBox .discounted .dsc {
    text-decoration: none;
  }

  .pBox .placeholder {
    padding: 0 0 0.5rem 0;
  }
  .pBox .placeholder .imageProd {
    height: 50px;
  }
  .pBox .placeholder .price {
    font-size: 12px;
  }
}

@container (max-width: 300px) {
  .pBox .pCont,
  .pBox .placeholder {
    grid-template-columns: 50px auto;
  }
  .pBox .imageProd {
    height: 50px;
  }
  .pBox h2 {
    font-size: 0.8rem;
    line-height: 1.1;
  }
  .pBox h2 span {
    font-size: 0.7rem;
    line-height: 1.1;
  }
  .pBox .price {
    font-size: 0.7rem;
  }
}
</style>
