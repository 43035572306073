import { reactive } from "vue";

export default reactive({
  bundles: [],
  sets: [],

  feesTrigger: false,

  fees: {},
  paymentMethods: [],

  loadedData: {
    bundles: false,
    sets: false,
    fees: false,
    paymentMethods: false,
  },

  setup() {
    this.getBundles();
    this.getSets();
  },

  promisify(fn) {
    return new Promise((res, rej) => {
      fn().then(res).catch(rej);
    });
  },

  async fetchDetailedCheckoutData() {
    const promises = [this.promisify(() => this.getFees()), this.promisify(() => this.getPaymentMethods())];
    await Promise.all(promises);
  },

  analyzeUpdateName(name) {
    if (name.startsWith("Mgiełka Sensualny")) {
      return ["Mgiełka Sensualna", name.slice(17)];
    }
    if (name.startsWith("Świeca Sojowa")) {
      return ["Świeca Sojowa", name.slice(14)];
    }
    if (name.startsWith("Zestaw")) {
      return [name, "Zestaw produktów MyAURA"];
    }
    return name;
  },

  priceString(price) {
    if (price === 0) return "0zł";
    if (isNaN(price)) return "BŁĄD";
    return `${price}`.slice(0, -2) + "," + `${price}`.slice(-2) + "zł";
  },

  async getSets() {
    try {
      const res = await fetch("/api/orders/sets", {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        this.sets = data.data;
        this.loadedData.sets = true;
      }
    } catch (err) {
      console.error(err);
    }
  },

  async getBundles() {
    try {
      const res = await fetch("/api/bundles", {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        this.bundles = data.data;
        this.loadedData.bundles = true;
      }
    } catch (err) {
      console.error(err);
    }
  },

  async getFees() {
    try {
      const res = await fetch("/api/orders/fees", {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        this.fees = data.data;
        this.loadedData.fees = true;
        this.feesTrigger = !this.feesTrigger;
      }
    } catch (err) {
      console.error(err);
    }
  },

  async getPaymentMethods() {
    try {
      const res = await fetch(`/api/orders/paymentMethods`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        this.paymentMethods = data.data;
        this.loadedData.paymentMethods = true;
      }
    } catch (err) {
      console.warn(err);
    }
  },

  calculateCartValue(cart) {
    let sum = 0;
    cart.forEach((item) => {
      sum += item.product.price * item.quantity;
    });
    return sum;
  },

  calculateProductDiscount(discount, item) {
    let total = 0;
    const product = item.product;
    const quantity = item.quantity;
    if (discount.discountMode === "percentage") {
      item.discount = Math.floor((product.price * discount.discountValue) / 100);
      item._appliedDiscount = true;
      total = item.discount * quantity;
    } else if (discount.discountMode === "constant") {
      item.discount = discount.discountValue;
      item._appliedDiscount = true;
      total = item.discount * quantity;
    }
    return total;
  },

  calculateDiscount(mode, value, price) {
    if (mode === "percentage") {
      return Math.floor((value / 100) * price);
    } else if (mode === "constant") {
      return value;
    }
    return 0;
  },

  analyzeCartDiscount(cart, discount) {
    const summary = {
      valueDiscounted: 0,
    };
    if (discount !== null) {
      discount.appliesTo.forEach((a) => {
        cart.forEach((item) => {
          if (item._usedInBundle) return;
          if (item.product.type !== "SET") {
            if (a.type === item.product.productLine && a.element === item.product.element) {
              summary.valueDiscounted += this.calculateProductDiscount(discount, item);
            } else if (a.type === item.product.productLine && !a.element) {
              summary.valueDiscounted += this.calculateProductDiscount(discount, item);
            } else if (a.element === item.product.element && !a.type) {
              summary.valueDiscounted += this.calculateProductDiscount(discount, item);
            }
          } else {
            if (a.type === item.product.name) {
              summary.valueDiscounted += this.calculateProductDiscount(discount, item);
            }
          }
        });
      });
    }
    return summary;
  },

  analyzeCartBundles(cart) {
    const summary = {
      valueDiscounted: 0,
      usedBundles: [],
    };
    this.bundles.forEach((bundle) => {
      let bundleUsed = false;
      let allRequiredProductsInCart = true;
      bundle.requiredProducts.forEach((p) => {
        let currentCount = p.quantity;
        cart.forEach((item) => {
          if (item._usedInBundle) return;
          if (item.product.type === "SET") {
            if (item.product.name === p.type) {
              if (!item._usedInBundle) {
                currentCount -= item.quantity;
                item._usedInBundle = true;
              }
            }
          } else {
            let itemQualifies = false;
            if (p.type && p.element) {
              if (item.product.productLine === p.type && item.product.element === p.element) itemQualifies = true;
            } else if (p.type) {
              if (item.product.productLine === p.type) itemQualifies = true;
            } else if (p.element) {
              if (item.product.element === p.element) itemQualifies = true;
            }
            if (!itemQualifies) return;
            currentCount -= item.quantity;
            item._usedInBundle = true;
          }
        });
        if (currentCount > 0) {
          allRequiredProductsInCart = false;
        }
      });
      if (allRequiredProductsInCart !== true) return;
      bundle.bundledProducts.forEach((p) => {
        let applied = false;
        cart.forEach((item) => {
          if (applied) return;
          if (item._usedInBundle) return;
          if (item.product.type === "SET") {
            if (item.name === p.type) {
              if (item.quantity > p.quantity) {
                cart.push({
                  product: { ...item.product },
                  quantity: item.quantity - p.quantity,
                });
                item.quantity = p.quantity;
              }
              item.discount = item.product.price * item.quantity;
              summary.valueDiscounted += item.product.price * item.quantity;
              item._usedInBundle = true;
              applied = true;
            }
          } else {
            let itemQualifies = false;
            if (p.type && p.element) {
              if (item.product.productLine === p.type && item.product.element === p.element) itemQualifies = true;
            } else if (p.type) {
              if (item.product.productLine === p.type) itemQualifies = true;
            } else if (p.element) {
              if (item.product.element === p.element) itemQualifies = true;
            }
            if (!itemQualifies) return;
            if (item.quantity > p.quantity) {
              cart.push({
                product: { ...item.product },
                quantity: item.quantity - p.quantity,
              });
              item.quantity = p.quantity;
            }
            item.discount = item.product.price * item.quantity;
            summary.valueDiscounted += item.product.price * item.quantity;
            item._usedInBundle = true;
            applied = true;
          }
          bundleUsed = true;
        });
      });
      if (bundleUsed) {
        summary.usedBundles.push(bundle);
      }
    });
    return summary;
  },

  async genereteCartCheckoutData(data) {
    try {
      if (!this.loadedData.sets) await this.getSets();
      if (!this.loadedData.fees) await this.getFees();
      if (!this.loadedData.bundles) await this.getBundles();

      const { cart, discount, delivery } = data;

      // Creating a list of all products (not sets)
      const listOfItemIds = [];
      cart.forEach((item) => {
        if (typeof item.product === "string") {
          if (item.product.startsWith("SET")) return;
          return listOfItemIds.push(item.product);
        }
        listOfItemIds.push(item.product._id);
      });

      // Request all the products details
      const req = await fetch("/api/products/many", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ids: listOfItemIds }),
      });
      const res = await req.json();

      // If the request doesn't succeed, change the button to error
      if (req.status !== 200) throw new Error("API request failed!");

      // Creating return object
      let checkout = {
        sum: 0,
        discount: {},
        items: [],
        deliveryFee: 0,
      };

      cart.forEach((item) => {
        if (typeof item.product === "string" && item.product.startsWith("SET")) {
          const [type, name] = item.product.split(":");
          const set = this.sets.find((t) => t.name === name);
          checkout.sum += item.quantity * set.price;
          return checkout.items.push({
            product: {
              name,
              type,
              _id: item.product,
              preview: set.preview,
              price: set.price,
            },
            quantity: item.quantity,
          });
        } else {
          if (typeof item.product === "string") {
            const detailedItem = res.data.find((product) => product._id === item.product);
            checkout.sum += detailedItem.price * item.quantity;
            return checkout.items.push({
              product: detailedItem,
              quantity: item.quantity,
            });
          } else {
            const detailedItem = res.data.find((product) => product._id === item.product._id);
            checkout.sum += detailedItem.price * item.quantity;
            return checkout.items.push({
              product: detailedItem,
              quantity: item.quantity,
            });
          }
        }
      });

      const cartDiscountBundles = this.analyzeCartBundles(checkout.items);
      checkout.discount.cart = cartDiscountBundles.valueDiscounted;
      checkout.bundlesUsed = cartDiscountBundles.usedBundles;

      const cartDiscountDiscounts = this.analyzeCartDiscount(checkout.items, discount);
      checkout.discount.cart += cartDiscountDiscounts.valueDiscounted;

      checkout.discount.delivery = 0;
      checkout.discount.order = 0;

      if (delivery && this.fees[delivery.type]) {
        checkout.deliveryFee = this.fees[delivery.type];
      }

      if (discount !== null) {
        const deliveryDiscount = discount.appliesTo.some((a) => a.type === "delivery");
        if (deliveryDiscount) {
          checkout.discount.delivery = this.calculateDiscount(discount.discountMode, discount.discountValue, checkout.deliveryFee);
        }

        const orderDiscount = discount.appliesTo.every((a) => a.type === "order");
        if (orderDiscount) {
          const total = checkout.sum + checkout.deliveryFee;
          checkout.discount.order = this.calculateDiscount(discount.discountMode, discount.discountValue, total);
        }
      }

      checkout.discount.total = checkout.discount.order + checkout.discount.delivery + checkout.discount.cart;

      if (checkout.sum - checkout.discount.total > 15000) {
        checkout.discount.delivery = checkout.deliveryFee;
        checkout.discount.total = checkout.discount.order + checkout.discount.delivery + checkout.discount.cart;
      }

      checkout.sum += checkout.deliveryFee;

      return checkout;
    } catch (err) {
      console.warn(err);
    }
  },
});
